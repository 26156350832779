// Translation
import { useTranslation as Translation } from 'react-i18next'

export const cardData = () => {
  const { t } = Translation()

  return [
    {
      number: 1,
      title: t('EASY_TO_START'),
      text: t('CARD1_TEXT'),
      colorTitle: 'color-violet',
      colorText: 'color-violet',
      colorNumber: 'color-red',
      bgColor: 'bg-color-white',
    },
    {
      number: 2,
      title: t('DATA_PRIVACY'),
      text: t('CARD2_TEXT'),
      colorTitle: 'color-violet',
      colorText: 'color-violet',
      colorNumber: 'color-white',
      bgColor: 'bg-color-red',
    },
    {
      number: 3,
      title: t('SMART_SECURITY'),
      text: t('CARD3_TEXT'),
      colorTitle: 'color-violet',
      colorText: 'color-violet',
      colorNumber: 'color-red',
      bgColor: 'bg-color-geraldine',
    },
    {
      number: 4,
      title: t('COMPETITIVE_FEES'),
      text: t('CARD4_TEXT'),
      colorTitle: 'color-violet',
      colorText: 'color-violet',
      colorNumber: 'color-white',
      bgColor: 'bg-color-rose',
    },
  ]
}
