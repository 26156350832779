// Style
import './GetStarter.style.scss'

// React library
import React from 'react'

// Component
import { ButtonIcon, Input } from '../../components'

// SVG
import appleIcon from '../../assets/svg/appstore.svg'
import playIcon from '../../assets/svg/play.svg'
import aupArrow from '../../assets/svg/send.svg'

// Translation
import { useTranslation } from 'react-i18next'

export const GetStarter = () => {
  const { t } = useTranslation()
  const [email, setEmail] = React.useState<string>('')

  const handleChange = (e: React.SyntheticEvent) => {
    const { value } = e.currentTarget as HTMLInputElement
    setEmail(value)
  }

  const clickHandler = (link: string) => {
    window.open(link)
  }

  return (
    <div className="container">
      <h1 className="font color-white center get-starter-title">
        {t('GET_STARTER_DOWNLOAD_THE_APP')}
      </h1>
      <div className="center get-starter-container-button">
        <div className="get-starter-space-button">
          <ButtonIcon
            onClick={() =>
              clickHandler('https://billetero.sng.link/D67p7/7ipm/hwjv')
            }
            label={t('DOWNLOAD')}
            btColor={'bg-color-red'}
            btColorText={'color-white button-icon-style'}
            icon={appleIcon}
          />
        </div>
        <div className="get-starter-space-button">
          <ButtonIcon
            onClick={() =>
              clickHandler('https://billetero.sng.link/D67p7/saok/er8y')
            }
            label={t('DOWNLOAD')}
            btColor={'bg-color-red'}
            btColorText={'color-white'}
            icon={playIcon}
          />
        </div>
      </div>
      <div className="get-starter-footer-container">
        <div className="get-starter-footer flex">
          <div className="get-starter-footer-btn">
            <ul className="get-starter-media-list">
              <li>
                <a
                  className="font color-red get-starter-media-list-link"
                  href="mailto:info@billetero.xyz"
                >
                  {t('CONTACT_US')}
                </a>
              </li>
              <li>
                <a
                  className="font color-white get-starter-media-list-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/billetero"
                >
                  {t('LINKEDIN')}
                </a>
              </li>
              <li>
                <a
                  className="font color-white get-starter-media-list-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/Billetero_"
                >
                  {t('TWITTER')}
                </a>
              </li>
              <li>
                <a
                  className="font color-white get-starter-media-list-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://t.me/billeteroapp"
                >
                  {t('TELEGRAM')}
                </a>
              </li>
            </ul>

            <ul className="get-starter-list">
              <li>
                <a
                  className="font color-white get-starter-list-link"
                  href="/privacy-policy"
                >
                  {t('PRIVACY_POLICY')}
                </a>
              </li>
              <li>
                <a
                  className="font color-white get-starter-list-link"
                  href="/terms-conditions"
                >
                  {t('TERMS_AND_CONDITION')}
                </a>
              </li>
              <li>
                <a
                  className="font color-white get-starter-list-link"
                  href="#about"
                >
                  {t('COOKIES_POLICY')}
                </a>
              </li>
            </ul>
          </div>
          <div className="get-starter-title-input-container">
            <h1 className="font color-white get-starter-get-in-touch">
              {t('GET_IN_TOUCH')}
            </h1>
            <form
              action="https://xyz.us21.list-manage.com/subscribe/post?u=2fc3bc5117fcf3a52ad848de3&amp;id=a7e8ba86b8&amp;f_id=0011fae1f0"
              method="post"
              noValidate
            >
              <Input
                name="EMAIL"
                inputType={'email'}
                placeholder={t('EMAIL')}
                iconType={'submit'}
                value={email}
                id="mce-EMAIL"
                isRequired={true}
                onChange={handleChange}
                icon={aupArrow}
              />
            </form>
          </div>
        </div>
        <div className="get-starter-footer-text font color-white">
          Billetero is registered as official Virtual Asset Service Provider in
          the European Union. Billetero sp. z o.o. is a limited liability
          company registered in Poland. Billetero sp. z o.o. is registered with
          the Polish Financial Supervision Authority for the provision of
          account information services under number AISP3/2020.
        </div>
      </div>
    </div>
  )
}
