// Style
import './PrivacyPolicy.style.scss'

// React library
import React from 'react'
import { useNavigate } from 'react-router-dom'

// SVG
import logoIconViolet from '../../assets/svg/logo-radical-red.svg'
import hamburgerRadicalRed from '../../assets/svg/hamburgerRadicalRed.svg'

// Component
import { NavBar, Button } from '../../components'

export const PrivacyPolicy = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/')
  }

  return (
    <div className="bg-color-violet privacy-page-container">
      <NavBar
        showNavButton={false}
        srcLogo={logoIconViolet}
        navTextColor={'#ff264a'}
        icon={hamburgerRadicalRed}
      />
      <div className="container bg-color-violet">
        <div className="privacy-text-container">
          <p className="font privacy-space color-white privacy-text">
            Privacy Policy
          </p>
          <p className="font privacy-space color-white privacy-text">
            Last updated: July 27, 2023
          </p>
          <p className="font privacy-space color-white privacy-text">
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </p>
          <p className="font privacy-space color-white privacy-text">
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy.
          </p>
          <h1 className="font privacy-space color-red privacy-text-title">
            Interpretation and Definitions
          </h1>
          <h2 className="font privacy-space color-white privacy-text-sub-title">
            Interpretation
          </h2>
          <p className="font privacy-space color-white privacy-text">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h2 className="font privacy-space color-white privacy-text-sub-title">
            Definitions
          </h2>
          <p className="font privacy-space color-white privacy-text">
            For the purposes of this Privacy Policy:
          </p>
          <div className="privacy-container-space">
            <p className="font privacy-space color-white privacy-text">
              <em>Account&nbsp;</em> means a unique account created for You to
              access our Service or parts of our Service.
            </p>
            <p className="font privacy-space color-white privacy-text">
              <em>Affiliate&nbsp;</em> means an entity that controls, is
              controlled by or is under common control with a party, where
              &quot;control&quot; means ownership of 50% or more of the shares,
              equity interest or other securities entitled to vote for election
              of directors or other managing authority.
            </p>
            <p className="font privacy-space color-white privacy-text">
              <em>Application&nbsp;</em> means the software program provided by
              the Company downloaded by You on any electronic device, named
              Billetero
            </p>
            <p className="font privacy-space color-white privacy-text">
              <em>Company&nbsp;</em> (referred to as either &quot;the
              Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
              in this Agreement) refers to BILLETERO Sp z o.o (Ltd.).,
              PIOTRKOWSKA St, 90-006 Lodz, Poland AND its subsidiary Blockchain
              Superapp corp. ,221 W 9th St,PMB 182,Wilmington, DE 19801, USA.
            </p>
            <p className="font privacy-space color-white privacy-text">
              <em>Country&nbsp;</em> refers to: Poland, United States
            </p>
            <p className="font privacy-space color-white privacy-text">
              <em>Device&nbsp;</em> means any device that can access the Service
              such as a computer, a cellphone or a digital tablet.
            </p>
            <p className="font privacy-space color-white privacy-text">
              <em>Do Not Track&nbsp;</em> (DNT) is a concept that has been
              promoted by US regulatory authorities, in particular the U.S.
              Federal Trade Commission (FTC), for the Internet industry to
              develop and implement a mechanism for allowing internet users to
              control the tracking of their online activities across websites.
            </p>
            <p className="font privacy-space color-white privacy-text">
              <em>Personal Data&nbsp;</em> is any information that relates to an
              identified or identifiable individual.
            </p>
            <p className="font privacy-space color-white privacy-text">
              <em>Service&nbsp;</em> refers to the Application.
            </p>
            <p className="font privacy-space color-white privacy-text">
              <em>Service Provider&nbsp;</em> means any natural or legal person
              who processes the data on behalf of the Company. It refers to
              third-party companies or individuals employed by the Company to
              facilitate the Service, to provide the Service on behalf of the
              Company, to perform services related to the Service or to assist
              the Company in analyzing how the Service is used.
            </p>
            <p className="font privacy-space color-white privacy-text">
              <em>Usage Data&nbsp;</em> refers to data collected automatically,
              either generated by the use of the Service or from the Service
              infrastructure itself (for example, the duration of a page visit).
            </p>
            <p className="font privacy-space color-white privacy-text">
              <em>You&nbsp;</em> means the individual accessing or using the
              Service, or the company, or other legal entity on behalf of which
              such individual is accessing or using the Service, as applicable.
            </p>
          </div>
          <h1 className="font privacy-space color-red privacy-text-title">
            USER DATA
          </h1>
          <h2 className="font privacy-space color-white privacy-text-sub-title">
            Types of Data
          </h2>

          <h2 className="font privacy-space color-white privacy-text-sub-title">
            Personal Data
          </h2>
          <p className="font privacy-space color-white privacy-text">
            Our Service is decentralized, which means self-custodial. Hence,
            while using Our Service, We will never demand You to provide Us with
            certain personally identifiable information that can be used to
            contact or identify You. Personally identifiable information only
            includes:
          </p>
          <p className="font privacy-space color-white privacy-text">
            Usage Data
          </p>
          <p className="font privacy-space color-white privacy-text">
            Exceptions:
          </p>
          <p className="font privacy-space color-white privacy-text">
            While using Our Service on the Website{' '}
            <a href="/">http://billetero.xyz</a> and the Application, Users have
            the option to voluntarily sign up with their email address to
            receive news and updates about the Service. Hence, We may collect,
            with Your prior permission:
          </p>
          <p className="font privacy-space color-white privacy-text">
            Email addresses
          </p>

          <h2 className="font privacy-space color-white privacy-text-sub-title">
            Usage Data
          </h2>
          <p className="font privacy-space color-white privacy-text">
            Usage Data is collected automatically when using the Service.
          </p>
          <p className="font privacy-space color-white privacy-text">
            Usage Data may include information such as Your Device’s Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </p>
          <p className="font privacy-space color-white privacy-text">
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </p>
          <p className="font privacy-space color-white privacy-text">
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </p>
          <h2 className="font privacy-space color-white privacy-text-sub-title">
            Information Collected while Using the Application
          </h2>
          <p className="font privacy-space color-white privacy-text">
            While using Our Application, in order to provide features of Our
            Application, We may collect, with Your prior permission:
          </p>
          <p className="font privacy-space color-white privacy-text">
            Pictures and other information from your Device’s camera and photo
            library
          </p>
          <p className="font privacy-space color-white privacy-text">
            We use this information to provide features of Our Service, to
            improve and customize Our Service. The information won’t be uploaded
            to the Company’s servers and/or a Service Provider’s server and it
            will be simply stored on Your device.
          </p>
          <p className="font privacy-space color-white privacy-text">
            You can enable or disable access to this information at any time,
            through Your Device settings.
          </p>
          <h2 className="font privacy-space color-white privacy-text-sub-title">
            Use of Your Personal Data with prior permission
          </h2>
          <p className="font privacy-space color-white privacy-text">
            The Company may use Personal Data with Your prior permission for the
            following purposes:
          </p>
          <p className="font privacy-space color-white privacy-text">
            <em>To provide You&nbsp;</em> with news, special offers and general
            information about other goods, services and events which we offer
            that are similar to those that you have already purchased or
            enquired about unless You have opted not to receive such
            information.
          </p>
          <p className="font privacy-space color-white privacy-text">
            <em>To manage Your requests:&nbsp;</em> To attend and manage Your
            requests to Us.
          </p>
          <p className="font privacy-space color-white privacy-text">
            <em>For other purposes:&nbsp;</em> We may use Your information for
            other purposes, such as data analysis, identifying usage trends,
            determining the effectiveness of our promotional campaigns and to
            evaluate and improve our Service, products, services, marketing and
            your experience.
          </p>
          <p className="font privacy-space color-white privacy-text">
            We may share Your personal information in the following situations:
          </p>
          <p className="font privacy-space color-white privacy-text">
            <em>With Service Providers:&nbsp;</em> We may share Your personal
            information with Service Providers to monitor and analyze the use of
            our Service, to advertise on third party websites to You after You
            visited our Service, for payment processing, to contact You.
          </p>
          <p className="font privacy-space color-white privacy-text">
            <em>For business transfers:&nbsp;</em> We may share or transfer Your
            personal information in connection with, or during negotiations of,
            any merger, sale of Company assets, financing, or acquisition of all
            or a portion of Our business to another company.
          </p>
          <p className="font privacy-space color-white privacy-text">
            <em>With Affiliates:&nbsp;</em> We may share Your information with
            Our affiliates, in which case we will require those affiliates to
            honor this Privacy Policy. Affiliates include Our parent company and
            any other subsidiaries, joint venture partners or other companies
            that We control or that are under common control with Us.
          </p>
          <p className="font privacy-space color-white privacy-text">
            <em>With Your consent:&nbsp;</em> We may disclose Your personal
            information for any other purpose with Your consent.
          </p>
          <h2 className="font privacy-space color-white privacy-text-sub-title">
            Retention of Your Personal Data
          </h2>
          <p className="font privacy-space color-white privacy-text">
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.{' '}
          </p>
          <p className="font privacy-space color-white privacy-text">
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </p>
          <h2 className="font privacy-space color-white privacy-text-sub-title">
            Transfer of Your Personal Data
          </h2>
          <p className="font privacy-space color-white privacy-text">
            Your information, including Personal Data, is processed at the
            Company&#39;s operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </p>
          <p className="font privacy-space color-white privacy-text">
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </p>
          <p className="font privacy-space color-white privacy-text">
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </p>

          <h2 className="font privacy-space color-white privacy-text-sub-title">
            Delete Your Personal Data
          </h2>
          <p className="font privacy-space color-white privacy-text">
            You have the right to delete or request that We assist in deleting
            the Personal Data that We have collected about You.
          </p>
          <p className="font privacy-space color-white privacy-text">
            Our Service may give You the ability to delete certain information
            about You from within the Service.
          </p>
          <p className="font privacy-space color-white privacy-text">
            You may update, amend, or delete Your information at any time by
            signing in to Your Account, if you have one, and visiting the
            account settings section that allows you to manage Your personal
            information. You may also contact Us to request access to, correct,
            or delete any personal information that You have provided to Us.
          </p>
          <p className="font privacy-space color-white privacy-text">
            Please note, however, that We may need to retain certain information
            when we have a legal obligation or lawful basis to do so.
          </p>

          <h2 className="font privacy-space color-white privacy-text-sub-title">
            Disclosure of Your Personal Data
          </h2>

          <p className="font privacy-space color-white privacy-text">
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>
          <h2 className="font privacy-space color-white privacy-text-sub-title">
            Law enforcement
          </h2>
          <p className="font privacy-space color-white privacy-text">
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>
          <h2 className="font privacy-space color-white privacy-text-sub-title">
            Other legal requirements
          </h2>
          <p className="font privacy-space color-white privacy-text">
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </p>
          <p className="font privacy-space color-white privacy-text">
            Comply with a legal obligation
          </p>
          <p className="font privacy-space color-white privacy-text">
            Protect and defend the rights or property of the Company
          </p>
          <p className="font privacy-space color-white privacy-text">
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </p>
          <p className="font privacy-space color-white privacy-text">
            Protect the personal safety of Users of the Service or the public
          </p>
          <p className="font privacy-space color-white privacy-text">
            Protect against legal liability
          </p>
          <h2 className="font privacy-space color-white privacy-text-sub-title">
            Security of Your Personal Data
          </h2>
          <p className="font privacy-space color-white privacy-text">
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>
          <h1 className="font privacy-space color-red privacy-text-title">
            Detailed Information on the Processing of Your Personal Data
          </h1>
          <p className="font privacy-space color-white privacy-text">
            The Service Providers We use may have access to Your Personal Data.
            These third-party vendors collect, store, use, process and transfer
            information about Your activity on Our Service in accordance with
            their Privacy Policies.
          </p>
          <h2 className="font privacy-space color-white privacy-text-sub-title">
            Analytics
          </h2>
          <p className="font privacy-space color-white privacy-text">
            We may use third-party Service providers to monitor and analyze the
            use of our Service.
          </p>
          <div className="privacy-container-space">
            <h2 className="font privacy-space color-white privacy-text-sub-title">
              Google Analytics
            </h2>
            <p className="font privacy-space color-white privacy-text">
              Google Analytics is a web analytics service offered by Google that
              tracks and reports website traffic. Google uses the data collected
              to track and monitor the use of our Service. This data is shared
              with other Google services. Google may use the collected data to
              contextualize and personalize the ads of its own advertising
              network.
            </p>
            <p className="font privacy-space color-white privacy-text">
              You may opt-out of certain Google Analytics features through your
              mobile device settings, such as your device advertising settings
              or by following the instructions provided by Google in their
              Privacy Policy:{' '}
              <a href="https://policies.google.com/privacy">
                https://policies.google.com/privacy
              </a>
            </p>
            <p className="font privacy-space color-white privacy-text">
              For more information on the privacy practices of Google, please
              visit the Google Privacy &amp; Terms web page:
              <a href="https://policies.google.com/privacy">
                https://policies.google.com/privacy
              </a>
            </p>
            <h2 className="font privacy-space color-white privacy-text-sub-title">
              Firebase
            </h2>
            <p className="font privacy-space color-white privacy-text">
              You may opt-out of certain Firebase features through your mobile
              device settings, such as your device advertising settings or by
              following the instructions provided by Google in their Privacy
              Policy:
              <a href="https://policies.google.com/privacy">
                https://policies.google.com/privacy
              </a>
            </p>
            <p className="font privacy-space color-white privacy-text">
              We also encourage you to review the Google&#39;s policy for
              safeguarding your data:
              <a href="https://support.google.com/analytics/answer/6004245">
                https://support.google.com/analytics/answer/6004245
              </a>
            </p>
            <p className="font privacy-space color-white privacy-text">
              For more information on what type of information Firebase
              collects, please visit the How Google uses data when you use our
              partners&#39; sites or apps webpage:
              <a href="https://policies.google.com/technologies/partner-sites">
                https://policies.google.com/technologies/partner-sites
              </a>
            </p>
            <h2 className="font privacy-space color-white privacy-text-sub-title">
              Singular
            </h2>
            <p className="font privacy-space color-white privacy-text">
              Their Privacy Policy can be viewed at
              <a href="https://www.singular.net/privacy-policy/">
                https://www.singular.net/privacy-policy/
              </a>
            </p>
          </div>
          <h2 className="font privacy-space color-white privacy-text-sub-title">
            Email Marketing
          </h2>
          <p className="font privacy-space color-white privacy-text">
            We may use Your Personal Data to contact You with newsletters,
            marketing or promotional materials and other information that may be
            of interest to You. You may opt-out of receiving any, or all, of
            these communications from Us by following the unsubscribe link or
            instructions provided in any email We send or by contacting Us.
          </p>
          <p className="font privacy-space color-white privacy-text">
            We may use Email Marketing Service Providers to manage and send
            emails to You.
          </p>
          <div className="privacy-container-space">
            <h2 className="font privacy-space color-white privacy-text-sub-title">
              Mailchimp
            </h2>
            <p className="font privacy-space color-white privacy-text">
              Mailchimp is an email marketing sending service provided by The
              Rocket Science Group LLC.
            </p>
            <p className="font privacy-space color-white privacy-text">
              For more information on the privacy practices of Mailchimp, please
              visit their Privacy policy:
              <a href="https://mailchimp.com/legal/privacy/">
                https://mailchimp.com/legal/privacy/
              </a>
            </p>
          </div>

          <h2 className="font privacy-space color-white privacy-text-sub-title">
            Payments
          </h2>
          <p className="font privacy-space color-white privacy-text">
            The Company may provide paid products and/or services within the
            Service. In that case, we may use third-party services for payment
            processing (e.g. payment processors).
          </p>
          <p className="font privacy-space color-white privacy-text">
            The Company will NOT store or collect Your payment card details.
            That information is provided directly to Our third-party payment
            processors whose use of Your personal information is governed by
            their Privacy Policy. These payment processors adhere to the
            standards set by PCI-DSS as managed by the PCI Security Standards
            Council, which is a joint effort of brands like Visa, Mastercard,
            American Express and Discover. PCI-DSS requirements help ensure the
            secure handling of payment information.
          </p>

          <div className="privacy-container-space">
            <h2 className="font privacy-space color-white privacy-text-sub-title">
              Ramp
            </h2>
            <p className="font privacy-space color-white privacy-text">
              Their Privacy Policy can be viewed at
              <a href="https://ramp.com/legal/privacy-policy">
                https://ramp.com/legal/privacy-policy
              </a>
            </p>
          </div>

          <h2 className="font privacy-space color-white privacy-text-sub-title">
            Behavioral Remarketing
          </h2>
          <p className="font privacy-space color-white privacy-text">
            The Company uses remarketing services to advertise to You after You
            accessed or visited our Service. We and Our third-party vendors use
            cookies and non-cookie technologies to help Us recognize Your Device
            and understand how You use our Service so that We can improve our
            Service to reflect Your interests and serve You advertisements that
            are likely to be of more interest to You.
          </p>
          <p className="font privacy-space color-white privacy-text">
            These third-party vendors collect, store, use, process and transfer
            information about Your activity on Our Service in accordance with
            their Privacy Policies and to enable Us to:
          </p>
          <p className="font privacy-space color-white privacy-text">
            Measure and analyze traffic and browsing activity on Our Service
          </p>
          <p className="font privacy-space color-white privacy-text">
            Show advertisements for our products and/or services to You on
            third-party websites or apps
          </p>
          <p className="font privacy-space color-white privacy-text">
            Measure and analyze the performance of Our advertising campaigns
          </p>
          <p className="font privacy-space color-white privacy-text">
            Some of these third-party vendors may use non-cookie technologies
            that may not be impacted by browser settings that block cookies.
            Your browser may not permit You to block such technologies. You can
            use the following third-party tools to decline the collection and
            use of information for the purpose of serving You interest-based
            advertising:
          </p>

          <p className="font privacy-space color-white privacy-text">
            The NAI&#39;s opt-out platform:
            <a href="http://www.networkadvertising.org/choices/">
              http://www.networkadvertising.org/choices/
            </a>
          </p>
          <p className="font privacy-space color-white privacy-text">
            The EDAA&#39;s opt-out platform
            <a href="http://www.youronlinechoices.com/">
              http://www.youronlinechoices.com/
            </a>
          </p>
          <p className="font privacy-space color-white privacy-text">
            The DAA&#39;s opt-out platform:
            <a href="http://optout.aboutads.info/?c=2&amp;lang=EN">
              http://optout.aboutads.info/?c=2&amp;lang=EN
            </a>
          </p>

          <p className="font privacy-space color-white privacy-text">
            You may opt-out of all personalized advertising by enabling privacy
            features on Your mobile device such as Limit Ad Tracking (iOS) and
            Opt Out of Ads Personalization (Android). See Your mobile device
            Help system for more information.
          </p>
          <p className="font privacy-space color-white privacy-text">
            We may share information, such as hashed email addresses (if
            available) or other online identifiers collected on Our Service with
            these third-party vendors. This allows Our third- party vendors to
            recognize and deliver You ads across devices and browsers. To read
            more about the technologies used by these third-party vendors and
            their cross-device capabilities please refer to the Privacy Policy
            of each vendor listed below.
          </p>
          <p className="font privacy-space color-white privacy-text">
            The third-party vendors We use are:
          </p>
          <div className="privacy-container-space">
            <h2 className="font privacy-space color-white privacy-text-sub-title">
              Google Ads (AdWords)
            </h2>
            <p className="font privacy-space color-white privacy-text">
              Google Ads (AdWords) remarketing service is provided by Google
              Inc.
            </p>
            <p className="font privacy-space color-white privacy-text">
              You can opt-out of Google Analytics for Display Advertising and
              customise the Google Display Network ads by visiting the Google
              Ads Settings page:
              <a href="http://www.google.com/settings/ads">
                http://www.google.com/settings/ads
              </a>
            </p>
            <p className="font privacy-space color-white privacy-text">
              Google also recommends installing the Google Analytics Opt-out
              Browser Add-on -{' '}
              <a href="https://tools.google.com/dlpage/gaoptout">
                https://tools.google.com/dlpage/gaoptout
              </a>
              - for your web browser. Google Analytics Opt-out Browser Add-on
              provides visitors with the ability to prevent their data from
              being collected and used by Google Analytics.
            </p>
            <p className="font privacy-space color-white privacy-text">
              For more information on the privacy practices of Google, please
              visit the Google Privacy &amp; Terms web page:
              <a href="https://policies.google.com/privacy">
                https://policies.google.com/privacy
              </a>
            </p>
          </div>

          <h1 className="font privacy-space color-red privacy-text-title">
            &quot;Do Not Track&quot; Policy as Required by California Online
            Privacy Protection Act (CalOPPA)
          </h1>
          <p className="font privacy-space color-white privacy-text">
            Our Service does not respond to Do Not Track signals.
          </p>
          <p className="font privacy-space color-white privacy-text">
            However, some third party websites do keep track of Your browsing
            activities. If You are visiting such websites, You can set Your
            preferences in Your web browser to inform websites that You do not
            want to be tracked. You can enable or disable DNT by visiting the
            preferences or settings page of Your web browser.
          </p>
          <h1 className="font privacy-space color-red privacy-text-title">
            &quot;Do Not Track&quot; Policy as Required by California Online
            Children&#39;s and Youth Privacy
          </h1>
          <p className="font privacy-space color-white privacy-text">
            Our Service does not address anyone under the age of 17. Besides
            device data the We do NOT knowingly collect personally identifiable
            information from anyone, especially not under the age of 17. If You
            are a parent or guardian and You are aware that Your child has
            interacted with our Service please contact Us. If We become aware
            that We or our payment provider have collected Data from anyone
            under the age of 17 without verification of parental consent, We
            take steps to remove that information from Our servers.
          </p>
          <p className="font privacy-space color-white privacy-text">
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent&#39;s consent before We collect and use that
            information.
          </p>
          <h1 className="font privacy-space color-red privacy-text-title">
            Links to Other Websites
          </h1>
          <p className="font privacy-space color-white privacy-text">
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party&#39;s site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </p>
          <p className="font privacy-space color-white privacy-text">
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
          <h1 className="font privacy-space color-red privacy-text-title">
            Changes to this Privacy Policy
          </h1>
          <p className="font privacy-space color-white privacy-text">
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p className="font privacy-space color-white privacy-text">
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the
            &quot;Last updated&quot; date at the top of this Privacy Policy.
          </p>
          <p className="font privacy-space color-white privacy-text">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <h1 className="font privacy-space color-red privacy-text-title">
            Contact Us
          </h1>
          <p className="font privacy-space color-white privacy-text">
            If you have any questions about this Privacy Policy, You can contact
            us:
          </p>
          <p className="font privacy-space color-white privacy-text">
            By email:{' '}
            <a href="mailto: info@billetero.xyz">info@billetero.xyz</a>
          </p>
        </div>
        <div className="center privacy-back-btn">
          <Button
            class="sup-btn color-white bg-color-transparent"
            name="back"
            label="Go back"
            isSelected={true}
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  )
}
