// Style
import './MainPage.style.scss'

// React Library
import React from 'react'

// Controller
import { AnimationController } from '../../controller'

// Pages
import {
  HomePage,
  FeaturePage,
  FeatureSafePage,
  WhyBilleteroPage,
  About,
  Support,
  GetStarter,
} from '../index'

// Component
import { NavBar } from '../../components'

// SVG
import logoViolet from '../../assets/svg/logo-violet.svg'
import logoRed from '../../assets/svg/logo-radical-red.svg'
import hamburgerViolet from '../../assets/svg/hamburgerViolet.svg'
import hamburgerRed from '../../assets/svg/hamburgerRadicalRed.svg'

export const MainPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [setClass, _, isElem] = AnimationController()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [setClassValue, __, isElemVisible] = AnimationController()

  React.useEffect(() => setClass('.logo-violet-class-controller'), [setClass])

  React.useEffect(
    () => setClassValue('.nav-violet-class-controller'),
    [setClassValue]
  )

  return (
    <>
      <div className="main-page-container">
        <section className="bg-color-violet home-section">
          <NavBar
            showNavButton={true}
            srcLogo={isElem || isElemVisible ? logoViolet : logoRed}
            navTextColor={isElem || isElemVisible ? '#300632' : '#ff264a'}
            icon={isElem || isElemVisible ? hamburgerViolet : hamburgerRed}
          />
          <HomePage />
        </section>

        <section className="bg-color-violet feature-page" id="features">
          <FeaturePage />
        </section>

        <section className="bg-color-violet feature-safe-section">
          <FeatureSafePage />
        </section>

        <section className="bg-color-violet billetero-section">
          <WhyBilleteroPage />
        </section>

        <section className="bg-color-geraldine about-section" id="about">
          <About />
        </section>

        <section className="bg-color-violet contact-section" id="contact">
          <Support />
        </section>

        <section className="bg-color-violet get-starter-section">
          <GetStarter />
        </section>
      </div>
    </>
  )
}
