// Style
import './FeatureSafePage.style.scss'

// React Library
import React from 'react'

// SVG
import PhoneCard from '../../assets/svg/web-screenshot-3.svg'
import lineOne from '../../assets/svg/Fill 1 Copy 2.svg'
import lineTwo from '../../assets/svg/Fill 1 Copy 4.svg'
import lineThree from '../../assets/svg/Fill 1 Copy 5.svg'

// Translation
import { useTranslation } from 'react-i18next'

export const FeatureSafePage = () => {
  const { t } = useTranslation()

  return (
    <div className="container">
      <div className="flex">
        <div className="feature-safe-page-container">
          <h1 className="font color-white feature-safe-home-text">
            {t('YOUR_SAFE')}
          </h1>
          <h1 className="font color-white feature-safe-home-text">
            {t('AND_SAFE')}
          </h1>
          <h1 className="font color-white feature-safe-home-text space-margin-bottom">
            {t('PURCHASES_ONLINE')}
          </h1>
        </div>
        <div className="img-feature-safe-page">
          <img className="img-feature-safe" src={PhoneCard} alt="PhoneCard" />
        </div>
      </div>
      <div className="image-container">
        <div className="line-one">
          <img className="line-one-img" src={lineOne} alt="lineOne" />
        </div>
        <div className="line-two">
          <img className="line-two-img" src={lineTwo} alt="lineTwo" />
        </div>
        <div className="line-three">
          <img className="line-three-img" src={lineThree} alt="lineThree" />
        </div>
      </div>
    </div>
  )
}
