export const es = {
  translation: {
    BILLETERO_IS_YOUR_NEW: 'Billetero es tu nueva cartera',
    SIMPLE_SAFE_AND_SECURE: 'sencilla y segura para tus',
    CRYPTO_WALLET: 'criptomonedas.',
    SWAP_STORE: 'Almacena y intercambia tus',
    SEND_AND_CONNECT: 'tokens. Envía tus criptomonedas a',
    YOUR_FRIEND: 'tus amigos',
    YOUR_SAFE: 'Conecta con cualquier',
    AND_SAFE: 'aplicación descentralizada y',
    PURCHASES_ONLINE: 'haz tus compras online.',
    WHY_CHOOSE_BILLETERO: '¿Por qué Billetero?',
    WHY_CHOOSE_BILLETERO_SUB_TITLE:
      'Ya sea que compres por primera vez o tengas criptomonedas desde hace tiempo, Billetero lo hace todo más fácil.',
    EASY_TO_START: 'Fácil de iniciar',
    CARD1_TEXT:
      'No más registros complejos: con solo dos clics, su nueva billetera/cartera está lista para usar.',
    DATA_PRIVACY: 'Privacidad de datos',
    CARD2_TEXT:
      'Nunca le pediremos sus detalles de contacto como número de teléfono o email. No almacenaremos ni compartiremos sus datos de transacciones. Queda todo guardado en las blockchains.',
    SMART_SECURITY: 'Smart security',
    CARD3_TEXT:
      'Solo tú tienes acceso a tu billtera/cartera. Asegura tu cartera con tu código pin personal, FaceID y tu frase de seguridad.',
    COMPETITIVE_FEES: 'Tarifas competitivas',
    CARD4_TEXT:
      'Solo añadimos una pequeña tarifa cuando intercambias tus criptomonedas. Puedes enviar tus tokens a tus amigos gratis! Todas las transacciones son transparentes en el checkout.',
    ABOUT_BILLETERO: 'Sobre Billetero',
    ABOUT_1PARA_TEXT:
      'Somos un equipo internacional con sede en EE.UU y Europa. Nos encanta crear productos de calidad para consumo y creemos en la simplicidad, la facilidad de uso, la belleza y la funcionalidad. Por ese motivo hemos creado Billetero, una nueva billetera digital descentralizada para criptomonedas. Puedes usar nuestra billetera para comprar tokens, enviarlos a familiares, amigos y otras personas o cambiar entre diferentes monedas.',
    ABOUT_2PARA_TEXT:
      'Nuestra visión es acelerar la adopción generalizada de criptomonedas, NFT y tecnología blockchain, con la misión de capacitar a cada persona para que pueda comprar y compartir criptomonedas y almacenar activos digitales en una billetera descentralizada segura.',
    ABOUT_3PARA_TEXT:
      'Prueba Billetero en Android e iOS, invita a tus amigos y comienza a explorar el mundo de las criptomonedas. Si deseas ponerte en contacto con nosotros, puedes escribirnos a ',
    FOR_ANY_QUESTION_PLEASE_CONTACT_US: 'Para cualquier pregunta, por favor contáctanos',
    WHAT_IS_CRYPTOCURRENCY: '¿Qué es la criptomoneda?',
    GENERAL_TEXT1:
      'La criptomoneda es dinero digital descentralizado que se usa en línea. La criptomoneda se adhiere a una forma descentralizada de gobernanza y control en lugar de a una autoridad bancaria central. La criptomoneda opera a través de la tecnología de contabilidad distribuida, más comúnmente conocida como blockchain.',    WHAT_IS_A_CRYPTO_BILLETERO_WALLET: 'What is a crypto Billetero wallet?',
    WHAT_IS_A_CRYPTO_BILLETERO_WALLET: '¿Qué es una billetera criptográfica?',
    GENERAL_TEXT2:
      'Una billetera criptográfica (billetera de criptomonedas) es un software que permite a los usuarios comprar, vender, almacenar y usar criptomonedas. Una billetera criptográfica permite a los usuarios validar el saldo de una cuenta para proporcionar visibilidad sobre la cantidad de criptomonedas que posee el usuario. Una billetera criptográfica permite a los usuarios enviar y recibir transacciones de criptomonedas.',
    WHAT_IS_A_DECENTRALIZED_WALLET: '¿Qué es una billetera descentralizada?',
    GENERAL_TEXT3:
      'Una billetera descentralizada significa que el titular de las claves tendrá acceso a la billetera criptográfica. Le permite al titular usar las claves para enviar o recibir criptomonedas en cualquier lugar del planeta. Algunas personas consideran que una billetera descentralizada es anónima, pero no lo es.',
    WHAT_IS_BLOCKCHAIN_TECHNOLOGY: '¿Qué es la tecnología blockchain?',
    GENERAL_TEXT4:
      'Blockchain es esencialmente un libro de registro digital de transacciones que se duplica y distribuye por toda la red de sistemas informáticos en la cadena de bloques.',
    WHAT_IS_A_SMART_CONTRACT: '¿Qué es un contrato inteligente?',
    GENERAL_TEXT5:
      'Un contrato inteligente es un contrato autoejecutable con los términos del acuerdo entre el comprador y el vendedor escritos directamente en líneas de código. El código y los acuerdos se registran en la cadena de bloques.',
    WHAT_IS_ETHEREUM: '¿Qué es Ethereum?',
    GENERAL_TEXT6:
      'Ethereum es una plataforma de cadena de bloques descentralizada que establece una red peer-to-peer que ejecuta y verifica de forma segura el código de aplicación, llamado contratos inteligentes. Los contratos inteligentes permiten a los participantes realizar transacciones entre ellos sin una autoridad central confiable.',
    WHAT_IS_BILLETERO: '¿Qué es Billetero?',
    PRODUCT_TEXT1:
      'Billetero es una billetera criptográfica descentralizada para dispositivos móviles, disponible para iOS y Android.',
    HOW_DO_I_USE_BILLETERO: '¿Cómo uso Billetero?',
    PRODUCT_TEXT2:
      'Una vez que abra la aplicación, puede conectar su billetera criptográfica existente o crear una nueva. Luego, puede comprar criptomonedas. También puede transferir monedas que posee desde otra billetera a su billetera Billetero. Una vez que posee criptomonedas, puede intercambiarlas por otras, enviarlas a otras billeteras, como familiares y amigos, o aplicaciones y servicios que admiten la criptomoneda como método de transacción.',
    WHAT_PRODUCT_ARE_FEATURED_ON_BILLETERO_APP:
      '¿Qué productos se destacan en la aplicación Billetero?',
    PRODUCT_TEXT3:
      'Puede comprar, enviar e intercambiar criptomonedas. También puede conectarse a otras billeteras que posee y transferir fondos desde esa billetera a su Billetero.',
    HOW_DO_I_CONNECT_TO_AN_EXISTING_WALLET:
      '¿Cómo me conecto a una billetera existente?',
     PRODUCT_TEXT4:
      'Desde la pantalla de apertura al usar Billetero por primera vez: Agregar billetera existente -> Conectar una billetera. Desde la pantalla de su perfil una vez que ya ha creado una billetera: Pantalla de inicio - icono de perfil - conectar una billetera. Hay tres formas de conectar una billetera existente',
    PRODUCT_TEXT4_1: 'escanear el código QR de su billetera externa Alternativamente',
    PRODUCT_TEXT4_2:
      'si conoce la dirección de la billetera externa y la tiene lista, cópiela y péguela en la barra de texto en la pantalla Conectar una billetera.',
    PRODUCT_TEXT4_3:
      'Si la dirección de la billetera externa está almacenada como un archivo en su teléfono, simplemente puede cargarla en la pantalla Conectar una billetera.',
    WHAT_CRYPTOCURRENCIES_ARE_AVAILABLE_ON_BILLETERO:
      '¿Qué criptomonedas están disponibles en Billetero?',
    PRODUCT_TEXT5:
      'Por ahora, puede comprar y usar todos los tokens de criptomonedas que forman parte de la cadena de bloques Ethereum. Seguiremos agregando más constantemente.',
    IS_BILLETERO_FREE: '¿Billetero es gratis?',
    PRODUCT_TEXT6:
      'Billetero es de uso gratuito. Dentro de la aplicación tienes la opción de comprar criptomonedas a través de tarjeta de crédito, Google Pay o Apple Pay.',
    WHAT_ARE_BILLETERO_TRANSACTION_FEES:
      '¿Cuáles son las tarifas de transacción de Billetero?',
    PRODUCT_TEXT7:
      'Billetero cobra 0,08% por intercambiar criptomonedas, es decir, 0,08% de la cantidad que se está intercambiando.',
    HOW_MANY_BLOCKCHAINS_CRYPTOCURRENCIES_DOES_BILLETERO_SUPPORT:
      '¿Cuántas cadenas de bloques / criptomonedas admite Billetero?',
    PRODUCT_TEXT8:
      'BIlletero actualmente admite la cadena de bloques Ethereum. Agregaremos más cadenas muy pronto.',
    HOW_SECURE_IS_BILLETERO: '¿Qué tan seguro es Billetero?',
    SECURITY_TEXT1: 'Hay dos formas principales de proteger su billetera Billetero.',
    SECURITY_TEXT1_1:
      'Su frase de contraseña que se genera aleatoriamente cuando crea una nueva billetera es su clave privada para su billetera. Solo usted la conoce y la posee. Guárdala y no la pierdas.',
    SECURITY_TEXT1_2: 'Proteja su billetera con un código de acceso y/o usando FaceID.',
    SECURITY_TEXT1_BOTTOM_TEXT:
      'Además, Billetero se ha creado utilizando plataformas web3 que añaden capas de seguridad adicionales a la aplicación móvil, como una clave cliente y maestra, así como permisos a nivel de clase, permisos de puntero, permisos de autenticación y más. No dude en ponerse en contacto con nosotros en este correo electrónico info@billtero.xyz para obtener más información sobre las medidas de seguridad.',
    DOES_BILLETERO_PROTECT_MY_PRIVACY: 'Does Billetero protect my privacy?',
    SECURITY_TEXT2_1:
      'A diferencia de las aplicaciones y sitios web centralizados, Billetero es una plataforma descentralizada. Esto significa que nunca le solicitaremos sus datos de contacto personales, como su correo electrónico o número de teléfono. Tu mantienes tu privacidad. A menos que comparta su correo electrónico para recibir actualizaciones de productos y marketing voluntariamente, nunca le enviaremos SPAM.',
    SECURITY_TEXT2_2:
      'Billetero no almacena ningún dato de transacción como otros sitios centralizados de tecnología financiera o banca. Todos ellos están en la cadena de bloques.',
    SECURITY_TEXT2_3:
      'Usted es la única persona que conoce su frase de contraseña. No tenemos acceso a ella, usted sí. Por eso es esencial anotarla y guardarla en un archivo para no perderla. Si la pierde, no podemos ayudarlo a recuperarla, ya que no la conocemos. Lo mismo ocurre con el código de acceso que crea para proteger su billetera.',
    GENERAL: 'Geral',
    PRODUCT: 'Produto',
    SECURITY_PRIVACY: 'Seguridad & Privacidad',
    GET_STARTER_DOWNLOAD_THE_APP: 'Empieza y descarga Billetero',
    DOWNLOAD: 'download',
    CONTACT_US: 'Contact us',
    LINKEDIN: 'Linkedin',
    TWITTER: 'Twitter',
    TELEGRAM: 'Telegram',
    PRIVACY_POLICY: 'Política de privacidad',
    TERMS_AND_CONDITION: 'Términos y condiciones',
    COOKIES_POLICY: 'Política de cookies',
    GET_IN_TOUCH: 'Contacto',
    EMAIL: 'Email',
  },
}
