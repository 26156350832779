// Style
import './NavBar.style.scss'

// Type
import { urlParamType, NavBarType } from './type'

// React library
import React from 'react'
import { useLocation } from 'react-router-dom'

// SVG
import logo from '../../assets/svg/logo-icon-violet.svg'
import appleIcon from '../../assets/svg/appstore.svg'
import playIcon from '../../assets/svg/play.svg'

// Controller
import { PageSize } from '../../controller'

// Component
import { ButtonIcon } from '../index'

// Translation
import { useTranslation } from 'react-i18next'

export const NavBar = (props: NavBarType) => {
  const { t, i18n } = useTranslation()
  const [selectedLang, setSelectedLang] = React.useState({
    en: i18n.language === 'en',
    es: i18n.language === 'es',
  })

  const navRef = React.useRef<HTMLDivElement>(null)
  const { windowSize } = PageSize()
  const location = useLocation()

  const noActive = 'font non-decoration no-active'
  const active = 'font non-decoration active'

  const urlParam: urlParamType = {
    isFeatures: location.hash === '#features',
    isAbout: location.hash === '#about',
    isContact: location.hash === '#contact',
  }
  const clickHandler = (link: string) => {
    window.open(link)
  }

  React.useEffect(() => {
    if (!windowSize) return

    if (windowSize > 880) handleClose()
  }, [windowSize])

  const handleClose = () => {
    if (!navRef.current) return
    navRef.current.style.display = 'none'
  }

  const handleOpen = () => {
    if (!navRef.current) return
    navRef.current.style.display = 'block'
  }

  const handleLang = (e: React.SyntheticEvent) => {
    const { name } = e.currentTarget as HTMLButtonElement
    const { value } = e.currentTarget as HTMLSelectElement

    const lang = name || value

    i18n.changeLanguage(lang)

    setSelectedLang({
      en: lang === 'en',
      es: lang === 'es',
    })

    handleClose()
  }

  return (
    <div className="flex nav">
      <div className="logo-container">
        <img src={props.srcLogo} alt="Logo Billetero" className="logo-nav" />
      </div>
      {props.showNavButton ? (
        <div className="flex link-container">
          <a
            className={urlParam.isFeatures ? active : noActive}
            style={{ color: props.navTextColor }}
            href="#features"
          >
            {t('FEATURES')}
          </a>
          <a
            className={
              urlParam.isAbout ? `${active} nav-space` : `${noActive} nav-space`
            }
            style={{ color: props.navTextColor }}
            href="#about"
          >
            {t('ABOUT')}
          </a>
          <a
            className={
              urlParam.isContact
                ? `${active} nav-space`
                : `${noActive} nav-space`
            }
            style={{ color: props.navTextColor }}
            href="#contact"
          >
            {t('CONTACT')}
          </a>
          <select
            id="lang"
            onChange={handleLang}
            style={{ color: props.navTextColor }}
            className="font nav-space bg-color-transparent nav-lang-select"
          >
            <option selected={selectedLang.en} value="en">
              EN
            </option>
            <option selected={selectedLang.es} value="es">
              ES
            </option>
          </select>
        </div>
      ) : null}

      {props.showNavButton ? (
        <div className="nav-icon-dropdown" style={{ float: 'right' }}>
          <button onClick={() => handleOpen()} className="nav-btn-dropdown">
            <img
              src={props.icon}
              className="nav-img-dropdown"
              alt="Icon DropDown"
            />
          </button>
          <div ref={navRef} className="bg-color-red overlay">
            <div className="nav-top-dropdown">
              <img src={logo} alt="Logo" className="nav-logo-dropdown" />
              <button
                className="color-violet close-btn"
                onClick={() => handleClose()}
              >
                &times;
              </button>
            </div>
            <div className="overlay-content">
              <a
                onClick={() => handleClose()}
                className="font color-violet"
                href="#features"
              >
                {t('FEATURES')}
              </a>
              <a
                onClick={() => handleClose()}
                className="font color-violet"
                href="#about"
              >
                {t('ABOUT')}
              </a>
              <a
                onClick={() => handleClose()}
                className="font color-violet"
                href="#contact"
              >
                {t('CONTACT')}
              </a>
            </div>

            <div className="nav-btn-download">
              <ButtonIcon
                icon={appleIcon}
                btColorText={'color-white'}
                btColor={'bg-color-violet'}
                label={t('DOWNLOAD')}
                onClick={() =>
                  clickHandler(
                    'https://billetero.sng.link/D67p7/7ipm/hwjv'
                  )
                }
              />
              <ButtonIcon
                icon={playIcon}
                btColorText={'color-white'}
                btColor={'bg-color-violet'}
                label={t('DOWNLOAD')}
                onClick={() =>
                  clickHandler(
                    'https://billetero.sng.link/D67p7/saok/er8y'
                  )
                }
              />
            </div>
            <div className="nav-lang-container">
              <button
                name="en"
                onClick={handleLang}
                className={
                  !selectedLang.en
                    ? 'font bg-color-transparent nav-lang-btn-selected'
                    : 'font bg-color-transparent nav-lang-btn'
                }
              >
                English
              </button>
              <button
                name="es"
                onClick={handleLang}
                className={
                  !selectedLang.es
                    ? 'font bg-color-transparent nav-lang-btn-selected'
                    : 'font bg-color-transparent nav-lang-btn'
                }
              >
                Español
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}
