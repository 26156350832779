// Translation
import { useTranslation as translation } from 'react-i18next'

export const SummaryDataTitle = () => {
  const { t } = translation()

  return [
    {
      title: 'General',
      summaryTitle: t('WHAT_IS_CRYPTOCURRENCY'),
      text: t('GENERAL_TEXT1'),
    },
    {
      summaryTitle: t('WHAT_IS_A_CRYPTO_BILLETERO_WALLET'),
      text: t('GENERAL_TEXT2'),
    },
    {
      summaryTitle: t('WHAT_IS_A_DECENTRALIZED_WALLET'),
      text: t('GENERAL_TEXT3'),
    },
    {
      summaryTitle: t('WHAT_IS_BLOCKCHAIN_TECHNOLOGY'),
      text: t('GENERAL_TEXT4'),
    },
    {
      summaryTitle: t('WHAT_IS_A_SMART_CONTRACT'),
      text: t('GENERAL_TEXT5'),
    },
    {
      summaryTitle: t('WHAT_IS_ETHEREUM'),
      text: t('GENERAL_TEXT6'),
    },
    {
      title: 'Product',
      summaryTitle: t('WHAT_IS_BILLETERO'),
      text: t('PRODUCT_TEXT1'),
    },
    {
      summaryTitle: t('HOW_DO_I_USE_BILLETERO'),
      text: t('PRODUCT_TEXT2'),
    },
    {
      summaryTitle: t('WHAT_PRODUCT_ARE_FEATURED_ON_BILLETERO_APP'),
      text: t('PRODUCT_TEXT3'),
    },
    {
      summaryTitle: t('HOW_DO_I_CONNECT_TO_AN_EXISTING_WALLET'),
      text: t('PRODUCT_TEXT4'),
      listText: [
        t('PRODUCT_TEXT4_1'),
        t('PRODUCT_TEXT4_2'),
        t('PRODUCT_TEXT4_3'),
      ],
    },
    {
      summaryTitle: t('WHAT_CRYPTOCURRENCIES_ARE_AVAILABLE_ON_BILLETERO'),
      text: t('PRODUCT_TEXT5'),
    },
    {
      summaryTitle: t('IS_BILLETERO_FREE'),
      text: t('PRODUCT_TEXT6'),
    },
    {
      summaryTitle: t('WHAT_ARE_BILLETERO_TRANSACTION_FEES'),
      text: t('PRODUCT_TEXT7'),
    },
    {
      summaryTitle: t(
        'HOW_MANY_BLOCKCHAINS_CRYPTOCURRENCIES_DOES_BILLETERO_SUPPORT'
      ),
      text: t('PRODUCT_TEXT8'),
    },

    {
      title: 'Security',
      summaryTitle: t('HOW_SECURE_IS_BILLETERO'),
      text: t('SECURITY_TEXT1'),
      listText: [t('SECURITY_TEXT1_1'), t('SECURITY_TEXT1_2')],
      bottomText: t('SECURITY_TEXT1_BOTTOM_TEXT'),
    },
    {
      summaryTitle: t('DOES_BILLETERO_PROTECT_MY_PRIVACY'),
      listText: [
        t('SECURITY_TEXT2_1'),
        t('SECURITY_TEXT2_2'),
        t('SECURITY_TEXT2_3'),
      ],
    },
  ]
}
