// Global Theme Style
import './themes/global-theme.scss'

import ReactDOM from 'react-dom/client'
import React from 'react'
import { App } from './App'

// i18Next
import './I18n'

const rootNode = document.getElementById('root') as HTMLElement
const root = ReactDOM.createRoot(rootNode)

root.render(
  <React.Suspense fallback="">
    <App />
  </React.Suspense>
)
