// React Library
import React from 'react'

export const PageSize = () => {
  const [windowSize, setWindowSize] = React.useState<number | null>(null)

  const autoResize = () => setWindowSize(window.innerWidth)

  React.useEffect(() => {
    window.addEventListener('resize', autoResize)
    autoResize()
  })

  return { windowSize }
}
