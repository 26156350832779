export type ButtonDataType = {
  name: string
  label: string
  class: string
  isSelected: boolean
}[]
// Translation
import { useTranslation } from 'react-i18next'

export const ButtonData = () => {
  const { t } = useTranslation()

  return [
    {
      name: 'General',
      label: t('GENERAL'),
      class: 'sup-btn color-white bg-color-transparent',
      isSelected: true,
    },
    {
      name: 'Product',
      label: t('PRODUCT'),
      class: 'sup-btn color-white bg-color-transparent',
      isSelected: false,
    },
    {
      name: 'Security',
      label: t('SECURITY_PRIVACY'),
      class: 'sup-btn color-white bg-color-transparent',
      isSelected: false,
    },
  ]
}
