import React from 'react'

export const AnimationController = () => {
  const [classValue, setClassValue] = React.useState<string>('')
  const [removeClass, setRemoveClass] = React.useState<boolean>(true)
  const [isElemVisible, setIsElemVisible] = React.useState<boolean>(false)

  const observer = new IntersectionObserver(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show')
          setIsElemVisible(true)
        } else if (removeClass) {
          entry.target.classList.remove('show')
          setIsElemVisible(false)
        }
      })
    }
  )

  React.useEffect(() => {
    if (!classValue.length) return

    const animationElem = document.querySelectorAll(classValue)
    animationElem.forEach((el: Element) => observer.observe(el))
  })

  return [setClassValue, setRemoveClass, isElemVisible] as const
}
