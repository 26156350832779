// Style
import './Button.style.scss'

// React Library
import React from 'react'

// Type
import { ButtonType } from './type'

export const Button = (props: ButtonType) => {
  return (
    <>
      <button
        name={props.name}
        className={`${props.class} ${
          !props.isSelected ? 'component-btn' : ''
        } font center`}
        onClick={props.onClick}
      >
        {props.label}
      </button>
    </>
  )
}
