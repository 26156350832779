import React from 'react'

export const HorizontalSlider = () => {
  const [classNameSlider, setClassNameSlider] = React.useState<string>('')

  const slider: HTMLElement | null = classNameSlider.length
    ? (document.querySelector(classNameSlider) as HTMLElement)
    : null

  let mouseDown = false
  let startX = 0
  let scrollLeft = 0

  const startDragging = (e: MouseEvent) => {
    if (slider === null) return

    mouseDown = true
    startX = e.pageX - slider.offsetLeft
    scrollLeft = slider.scrollLeft
  }

  const stopDragging = () => (mouseDown = false)

  slider?.addEventListener('mousemove', (e: MouseEvent) => {
    e.preventDefault()
    if (!mouseDown) return

    const x = e.pageX - slider.offsetLeft
    const scroll = x - startX
    slider.scrollLeft = scrollLeft - scroll
  })

  // Add the event listeners
  slider?.addEventListener('mousedown', startDragging, false)
  slider?.addEventListener('mouseup', stopDragging, false)
  slider?.addEventListener('mouseleave', stopDragging, false)

  return { setClassNameSlider }
}
