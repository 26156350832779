// SVG
import PhoneCard from '../../assets/svg/Group 10 Copy 2.svg'

// Style
import './PhoneCard.style.scss'

// React library
import React from 'react'

export const CardPhone = () => {
  return (
    <>
      <img className="phone-card-style" src={PhoneCard} alt="Phone Card" />
    </>
  )
}
