import { use } from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { en, es } from './translation'

use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: false,
    keySeparator: false,
    interpolation: { escapeValue: true },
    fallbackLng: 'en',
    resources: {
      en: {
        ...en,
      },
      es: {
        ...es,
      },
    },
  })
