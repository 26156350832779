// Style
import './Card.style.scss'

// React Library
import React from 'react'

// Type
import { CardType } from './type'

export const Card = (props: CardType) => {
  return (
    <div className={`${props.bgColor} billetero-card-container`}>
      <h2 className={`${props.colorNumber} font billetero-card-number`}>
        {props.number}
      </h2>
      <h2 className={`${props.colorTitle} font billetero-card-title`}>
        {props.title}
      </h2>
      <p className={`${props.colorText} font billetero-card-text`}>
        {props.text}
      </p>
    </div>
  )
}
