// Style
import './About.style.scss'

// React Library
import React from 'react'

// SVG
import group from '../../assets/img/Group.png'

// Translation
import { useTranslation } from 'react-i18next'

export const About = () => {
  const { t } = useTranslation()

  return (
    <div className="about-container container">
      <div>
        <h1 className="logo-violet-class-controller font color-violet about-title">
          {t('ABOUT_BILLETERO')}
        </h1>
        <div className="about-text-container">
          <p className="font color-violet about-text">
            {t('ABOUT_1PARA_TEXT')}
          </p>
          <p className="font color-violet about-text">
            {t('ABOUT_2PARA_TEXT')}
          </p>
          <p className="font color-violet about-text">
            {t('ABOUT_3PARA_TEXT')}
            <a className="color-white" href="mailto: info@billetero.com">
              info@billetero.com
            </a>
            .
          </p>
        </div>
      </div>
      <img src={group} alt="Team Group" className="about-img" />
    </div>
  )
}
