// Style
import './Billetero.style.scss'

// React Library
import React from 'react'

// Component
import { Card } from '../../components'

// Animation
import { HorizontalSlider } from '../../controller'

// Data
import { cardData } from './CardData'

// Translation
import { useTranslation } from 'react-i18next'

export const WhyBilleteroPage = () => {
  const { t } = useTranslation()
  const { setClassNameSlider } = HorizontalSlider()

  React.useEffect(() => setClassNameSlider('.billetero-scroll-list'))

  return (
    <div className="container">
      <div className="billetero-container">
        <div className="center billetero-title-container">
          <h1 className="font color-white billetero-title">
            {t('WHY_CHOOSE_BILLETERO')}
          </h1>
        </div>
        <div className="center">
          <p className="font color-white billetero-sub-title">
            {t('WHY_CHOOSE_BILLETERO_SUB_TITLE')}
          </p>
        </div>
        <div className="center">
          <div className="flex billetero-scroll-list">
            {cardData().map((card, key) => {
              return (
                <div key={key}>
                  <Card
                    number={card.number}
                    title={card.title}
                    text={card.text}
                    colorTitle={card.colorTitle}
                    colorText={card.colorText}
                    colorNumber={card.colorNumber}
                    bgColor={card.bgColor}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
