// Style
import './Conditions.style.scss'

// React Library
import React from 'react'
import { useNavigate } from 'react-router-dom'

// SVG
import logoIconViolet from '../../assets/svg/logo-radical-red.svg'
import hamburgerRadicalRed from '../../assets/svg/hamburgerRadicalRed.svg'

// Component
import { NavBar, Button } from '../../components'

export const Conditions = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/')
  }

  return (
    <div className="bg-color-violet conditions-page-container">
      <NavBar
        showNavButton={false}
        srcLogo={logoIconViolet}
        navTextColor={'#ff264a'}
        icon={hamburgerRadicalRed}
      />
      <div className="container bg-color-violet">
        <div className="conditions-text-container">
          <p className="font conditions-space color-white conditions-text">
            Terms and Conditions
          </p>
          <p className="font conditions-space color-white conditions-text">
            Last updated: July 27, 2023
          </p>
          <p className="font conditions-space color-white conditions-text">
            Please read these terms and conditions carefully before using Our
            Service.
          </p>
          <h2 className="font conditions-space color-red conditions-text-title">
            Interpretation and Definitions
          </h2>
          <h2 className="font conditions-space color-white conditions-text-sub-title">
            Interpretation
          </h2>
          <p className="font conditions-space color-white conditions-text">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h2 className="font conditions-space color-white conditions-text-sub-title">
            Definitions
          </h2>
          <p className="font conditions-space color-white conditions-text">
            For the purposes of these Terms and Conditions:
          </p>
          \
          <div className="conditions-container-space">
            <p className="font conditions-space color-white conditions-text">
              <em>Application&nbsp;</em> means the software program provided by
              the Company downloaded by You on any electronic device, named
              Billetero
            </p>
            <p className="font conditions-space color-white conditions-text">
              <em>Application Store&nbsp;</em> means the digital distribution
              service operated and developed by Apple Inc. (Apple App Store) or
              Google Inc. (Google Play Store) in which the Application has been
              downloaded.
            </p>
            <p className="font conditions-space color-white conditions-text">
              <em>Affiliate&nbsp;</em> means an entity that controls, is
              controlled by or is under common control with a party, where
              &quot;control&quot; means ownership of 50% or more of the shares,
              equity interest or other securities entitled to vote for election
              of directors or other managing authority.
            </p>
            <p className="font conditions-space color-white conditions-text">
              <em>Country&nbsp;</em> refers to: Poland, United States
            </p>
            <p className="font conditions-space color-white conditions-text">
              <em>Company&nbsp;</em> (referred to as either &quot;the
              Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
              in this Agreement) refers to BILLETERO Sp z o.o (Ltd.).,
              PIOTRKOWSKA St, 90-006 Lodz, Poland AND its subsidiary Blockchain
              Superapp corp. ,221 W 9th St,PMB 182,Wilmington, DE 19801, USA.
            </p>
            <p className="font conditions-space color-white conditions-text">
              <em>Content&nbsp;</em> refers to content such as text, images, or
              other information that can be posted, uploaded, linked to or
              otherwise made available by You, regardless of the form of that
              content.
            </p>
            <p className="font conditions-space color-white conditions-text">
              <em>Device&nbsp;</em> means any device that can access the Service
              such as a computer, a cellphone or a digital tablet.
            </p>
            <p className="font conditions-space color-white conditions-text">
              <em>Feedback&nbsp;</em> means feedback, innovations or suggestions
              sent by You regarding the attributes, performance or features of
              our Service.
            </p>
            <p className="font conditions-space color-white conditions-text">
              <em>In-app Purchase&nbsp;</em> refers to the purchase of a
              product, item, service or Subscription made through the
              Application and subject to these Terms and Conditions and/or the
              Application Store&#39;s own terms and conditions.
            </p>
            <p className="font conditions-space color-white conditions-text">
              <em>Promotions&nbsp;</em> refer to contests, sweepstakes or other
              promotions offered through the Service.
            </p>
            <p className="font conditions-space color-white conditions-text">
              <em>Service&nbsp;</em> refers to the Application.
            </p>
            <p className="font conditions-space color-white conditions-text">
              <em>Terms and Conditions&nbsp;</em> (also referred as
              &quot;Terms&quot;) mean these Terms and Conditions that form the
              entire agreement between You and the Company regarding the use of
              the Service.
            </p>
            <p className="font conditions-space color-white conditions-text">
              <em>Third-party Social Media Service&nbsp;</em> means any services
              or content (including data, information, products or services)
              provided by a third-party that may be displayed, included or made
              available by the Service.
            </p>
            <p className="font conditions-space color-white conditions-text">
              <em>You&nbsp;</em> means the individual accessing or using the
              Service, or the company, or other legal entity on behalf of which
              such individual is accessing or using the Service, as applicable.
            </p>
          </div>
          <h2 className="font conditions-space color-red conditions-text-title">
            Acknowledgment
          </h2>
          <p className="font conditions-space color-white conditions-text">
            These are the Terms and Conditions governing the use of this Service
            and the agreement that operates between You and the Company. These
            Terms and Conditions set out the rights and obligations of all users
            regarding the use of the Service.
          </p>
          <p className="font conditions-space color-white conditions-text">
            Your access to and use of the Service is conditioned on Your
            acceptance of and compliance with these Terms and Conditions. These
            Terms and Conditions apply to all visitors, users and others who
            access or use the Service.
          </p>
          <p className="font conditions-space color-white conditions-text">
            By accessing or using the Service You agree to be bound by these
            Terms and Conditions. If You disagree with any part of these Terms
            and Conditions then You may not access the Service.
          </p>
          <p className="font conditions-space color-white conditions-text">
            You represent that you are over the age of 18. The Company does not
            permit those under 18 to use the Service.
          </p>
          <p className="font conditions-space color-white conditions-text">
            Your access to and use of the Service is also conditioned on Your
            acceptance of and compliance with the Privacy Policy of the Company.
            Our Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your personal information when You
            use the Application or the Website and tells You about Your privacy
            rights and how the law protects You. Please read Our Privacy Policy
            carefully before using Our Service.
          </p>
          <h2 className="font conditions-space color-red conditions-text-title">
            In-app Purchases
          </h2>
          <p className="font conditions-space color-white conditions-text">
            The Application may include In-app Purchases that allow you to buy
            products, services or Subscriptions.
          </p>
          <p className="font conditions-space color-white conditions-text">
            More information about how you may be able to manage In-app
            Purchases using your Device may be set out in the Application
            Store&#39;s own terms and conditions or in your Device&#39;s Help
            settings.
          </p>
          <p className="font conditions-space color-white conditions-text">
            In-app Purchases can only be consumed within the Application. If you
            make a In-app Purchase, that In-app Purchase cannot be cancelled
            after you have initiated its download. In-app Purchases cannot be
            redeemed for cash or other consideration or otherwise transferred.
          </p>
          <p className="font conditions-space color-white conditions-text">
            If any In-app Purchase is not successfully downloaded or does not
            work once it has been successfully downloaded, we will, after
            becoming aware of the fault or being notified to the fault by You,
            investigate the reason for the fault. We will act reasonably in
            deciding whether to provide You with a replacement In-app Purchase
            or issue You with a patch to repair the fault. In no event will We
            charge You to replace or repair the In-app Purchase. In the unlikely
            event that we are unable to replace or repair the relevant In-app
            Purchase or are unable to do so within a reasonable period of time
            and without significant inconvenience to You, We will authorize the
            Application Store to refund You an amount up to the cost of the
            relevant In-app Purchase. Alternatively, if You wish to request a
            refund, You may do so by contacting the Application Store directly.
          </p>
          <p className="font conditions-space color-white conditions-text">
            You acknowledge and agree that all billing and transaction processes
            are handled by the Application Store from where you downloaded the
            Application and are governed by that Application Store&#39;s own
            terms and conditions.
          </p>
          <p className="font conditions-space color-white conditions-text">
            If you have any payment related issues with In-app Purchases, then
            you need to contact the Application Store directly.
          </p>
          <h2 className="font conditions-space color-red conditions-text-title">
            Promotions
          </h2>
          <p className="font conditions-space color-white conditions-text">
            Any Promotions made available through the Service may be governed by
            rules that are separate from these Terms.
          </p>
          <p className="font conditions-space color-white conditions-text">
            If You participate in any Promotions, please review the applicable
            rules as well as our Privacy policy. If the rules for a Promotion
            conflict with these Terms, the Promotion rules will apply.
          </p>
          <h2 className="font conditions-space color-red conditions-text-title">
            Content
          </h2>
          <h2 className="font conditions-space color-white conditions-text-sub-title">
            Your Right to Post Content
          </h2>
          <p className="font conditions-space color-white conditions-text">
            Our Service allows You to post Content in form of profile images.
            You are responsible for the Content that You post to the Service,
            including its legality, reliability, and appropriateness.
          </p>
          <p className="font conditions-space color-white conditions-text">
            By posting Content to the Service, You grant Us the right and
            license to use, modify, publicly perform, publicly display,
            reproduce, and distribute such Content on and through the Service.
            You retain any and all of Your rights to any Content You submit,
            post or display on or through the Service and You are responsible
            for protecting those rights. You agree that this license includes
            the right for Us to make Your Content available to other users of
            the Service, who may also use Your Content subject to these Terms.
          </p>
          <p className="font conditions-space color-white conditions-text">
            You represent and warrant that: (i) the Content is Yours (You own
            it) or You have the right to use it and grant Us the rights and
            license as provided in these Terms, and (ii) the posting of Your
            Content on or through the Service does not violate the privacy
            rights, publicity rights, copyrights, contract rights or any other
            rights of any person.
          </p>
          <h2 className="font conditions-space color-white conditions-text-sub-title">
            Content Restrictions
          </h2>
          <p className="font conditions-space color-white conditions-text">
            The Company is not responsible for the content of the Service&#39;s
            users. You expressly understand and agree that You are solely
            responsible for the Content and for all activity that occurs under
            your account, whether done so by You or any third person using Your
            account.
          </p>
          <p className="font conditions-space color-white conditions-text">
            You may not transmit any Content that is unlawful, offensive,
            upsetting, intended to disgust, threatening, libelous, defamatory,
            obscene or otherwise objectionable. Examples of such objectionable
            Content include, but are not limited to, the following:
          </p>
          <p className="font conditions-space color-white conditions-text">
            Unlawful or promoting unlawful activity.
          </p>
          <p className="font conditions-space color-white conditions-text">
            Defamatory, discriminatory, or mean-spirited content, including
            references or commentary about religion, race, sexual orientation,
            gender, national/ethnic origin, or other targeted groups.
          </p>
          <p className="font conditions-space color-white conditions-text">
            Spam, machine – or randomly – generated, constituting unauthorized
            or unsolicited advertising, chain letters, any other form of
            unauthorized solicitation, or any form of lottery or gambling.
          </p>
          <p className="font conditions-space color-white conditions-text">
            Containing or installing any viruses, worms, malware, trojan horses,
            or other content that is designed or intended to disrupt, damage, or
            limit the functioning of any software, hardware or
            telecommunications equipment or to damage or obtain unauthorized
            access to any data or other information of a third person.
          </p>
          <p className="font conditions-space color-white conditions-text">
            Infringing on any proprietary rights of any party, including patent,
            trademark, trade secret, copyright, right of publicity or other
            rights.
          </p>
          <p className="font conditions-space color-white conditions-text">
            Impersonating any person or entity including the Company and its
            employees or representatives.
          </p>
          <p className="font conditions-space color-white conditions-text">
            Violating the privacy of any third person.
          </p>
          <p className="font conditions-space color-white conditions-text">
            False information and features.
          </p>
          <p className="font conditions-space color-white conditions-text">
            The Company reserves the right, but not the obligation, to, in its
            sole discretion, determine whether or not any Content is appropriate
            and complies with this Terms, refuse or remove this Content. The
            Company further reserves the right to make formatting and edits and
            change the manner of any Content. The Company can also limit or
            revoke the use of the Service if You post such objectionable
            Content. As the Company cannot control all content posted by users
            and/or third parties on the Service, you agree to use the Service at
            your own risk. You understand that by using the Service You may be
            exposed to content that You may find offensive, indecent, incorrect
            or objectionable, and You agree that under no circumstances will the
            Company be liable in any way for any content, including any errors
            or omissions in any content, or any loss or damage of any kind
            incurred as a result of your use of any content.
          </p>
          <h2 className="font conditions-space color-white conditions-text-sub-title">
            Content Backups
          </h2>
          <p className="font conditions-space color-white conditions-text">
            The Company does not store content uploaded by a users. the Company
            does not guarantee there will be no loss or corruption of data.
          </p>
          <p className="font conditions-space color-white conditions-text">
            The Company will provide support and attempt to troubleshoot any
            known or discovered issues that may affect the upload of Content.
            But You acknowledge that the Company has no liability related to the
            integrity of Content or the failure to successfully restore Content
            to a usable state.
          </p>
          <p className="font conditions-space color-white conditions-text">
            You agree to maintain a complete and accurate copy of any Content in
            a location independent of the Service.
          </p>
          <h2 className="font conditions-space color-red conditions-text-title">
            Copyright Policy
          </h2>
          <h2 className="font conditions-space color-white conditions-text-sub-title">
            Intellectual Property Infringement
          </h2>
          <p className="font conditions-space color-white conditions-text">
            We respect the intellectual property rights of others. It is Our
            policy to respond to any claim that Content posted on the Service
            infringes a copyright or other intellectual property infringement of
            any person.
          </p>
          <p className="font conditions-space color-white conditions-text">
            If You are a copyright owner, or authorized on behalf of one, and
            You believe that the copyrighted work has been copied in a way that
            constitutes copyright infringement that is taking place through the
            Service, You must submit Your notice in writing to the attention of
            our copyright agent via email at{' '}
            <a href="mailto: info@billetero.xyz">info@billetero.xyz</a> and
            include in Your notice a detailed description of the alleged
            infringement.
          </p>
          <p className="font conditions-space color-white conditions-text">
            You may be held accountable for damages (including costs and
            attorneys&#39; fees) for misrepresenting that any Content is
            infringing Your copyright.
          </p>
          <h2 className="font conditions-space color-white conditions-text-sub-title">
            DMCA Notice and DMCA Procedure for Copyright Infringement Claims
          </h2>
          <p className="font conditions-space color-white conditions-text">
            You may submit a notification pursuant to the Digital Millennium
            Copyright Act (DMCA) by providing our Copyright Agent with the
            following information in writing (see 17 U.S.C 512(c)(3) for further
            detail):
          </p>
          <p className="font conditions-space color-white conditions-text">
            An electronic or physical signature of the person authorized to act
            on behalf of the owner of the copyright&#39;s interest.
          </p>
          <p className="font conditions-space color-white conditions-text">
            A description of the copyrighted work that You claim has been
            infringed, including the URL (i.e., web page address) of the
            location where the copyrighted work exists or a copy of the
            copyrighted work.
          </p>
          <p className="font conditions-space color-white conditions-text">
            Identification of the URL or other specific location on the Service
            where the material that You claim is infringing is located.
          </p>
          <p className="font conditions-space color-white conditions-text">
            Your address, telephone number, and email address.
          </p>
          <p className="font conditions-space color-white conditions-text">
            A statement by You that You have a good faith belief that the
            disputed use is not authorized by the copyright owner, its agent, or
            the law.
          </p>
          <p className="font conditions-space color-white conditions-text">
            A statement by You, made under penalty of perjury, that the above
            information in Your notice is accurate and that You are the
            copyright owner or authorized to act on the copyright owner&#39;s
            behalf.
          </p>
          <p className="font conditions-space color-white conditions-text">
            You can contact our copyright agent via email at{' '}
            <a href="mailto: info@billetero.xyz">info@billetero.xyz</a>. Upon
            receipt of a notification, the Company will take whatever action, in
            its sole discretion, it deems appropriate, including removal of the
            challenged content from the Service.
          </p>
          <h2 className="font conditions-space color-red conditions-text-title">
            Intellectual Property
          </h2>
          <p className="font conditions-space color-white conditions-text">
            The Service and its original content (excluding Content provided by
            You or other users), features and functionality are and will remain
            the exclusive property of the Company and its licensors.
          </p>
          <p className="font conditions-space color-white conditions-text">
            The Service is protected by copyright, trademark, and other laws of
            both the Country and foreign countries.
          </p>
          <p className="font conditions-space color-white conditions-text">
            Our trademarks and trade dress may not be used in connection with
            any product or service without the prior written consent of the
            Company.
          </p>
          <h2 className="font conditions-space color-red conditions-text-title">
            Your Feedback to Us
          </h2>
          <p className="font conditions-space color-white conditions-text">
            You assign all rights, title and interest in any Feedback You
            provide the Company. If for any reason such assignment is
            ineffective, You agree to grant the Company a non-exclusive,
            perpetual, irrevocable, royalty free, worldwide right and license to
            use, reproduce, disclose, sub-license, distribute, modify and
            exploit such Feedback without restriction.
          </p>
          <h2 className="font conditions-space color-red conditions-text-title">
            Links to Other Websites
          </h2>
          <p className="font conditions-space color-white conditions-text">
            Our Service may contain links to third-party web sites or services
            that are not owned or controlled by the Company.
          </p>
          <p className="font conditions-space color-white conditions-text">
            The Company has no control over, and assumes no responsibility for,
            the content, privacy policies, or practices of any third party web
            sites or services. You further acknowledge and agree that the
            Company shall not be responsible or liable, directly or indirectly,
            for any damage or loss caused or alleged to be caused by or in
            connection with the use of or reliance on any such content, goods or
            services available on or through any such web sites or services.
          </p>
          <p className="font conditions-space color-white conditions-text">
            We strongly advise You to read the terms and conditions and privacy
            policies of any third- party web sites or services that You visit.
          </p>
          <h2 className="font conditions-space color-red conditions-text-title">
            Termination
          </h2>
          <p className="font conditions-space color-white conditions-text">
            We may terminate or suspend Your access immediately, without prior
            notice or liability, for any reason whatsoever, including without
            limitation if You breach these Terms and Conditions.
          </p>
          <p className="font conditions-space color-white conditions-text">
            Upon termination, Your right to use the Service will cease
            immediately.
          </p>
          <h2 className="font conditions-space color-red conditions-text-title">
            Limitation of Liability
          </h2>
          <p className="font conditions-space color-white conditions-text">
            In no event will the Company or its suppliers be liable to you or
            any third party for any direct, indirect, consequential, exemplary,
            incidental, special, or punitive damages, including lost profit,
            lost revenue, loss of data, or other damages arising from your use
            of the app, even if we have been advised of the possibility of such
            damages.
          </p>
          <p className="font conditions-space color-white conditions-text">
            To the maximum extent permitted by applicable law, in no event shall
            the Company or its suppliers be liable for any special, incidental,
            indirect, or consequential damages whatsoever (including, but not
            limited to, damages for loss of profits, loss of data or other
            information, for business interruption, for personal injury, loss of
            privacy arising out of or in any way related to the use of or
            inability to use the Service, third-party software and/or
            third-party hardware used with the Service, or otherwise in
            connection with any provision of this Terms), even if the Company or
            any supplier has been advised of the possibility of such damages and
            even if the remedy fails of its essential purpose.
          </p>
          <p className="font conditions-space color-white conditions-text">
            Some US states do not allow the exclusion of implied warranties or
            limitation of liability for incidental or consequential damages,
            which means that some of the above limitations may not apply. If
            these laws apply to you, some or all of the above disclaimers or
            limitations may not apply to you, and you may have additional
            rights.
          </p>
          <h2 className="font conditions-space color-red conditions-text-title">
            &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer
          </h2>
          <p className="font conditions-space color-white conditions-text">
            The Service is provided to You &quot;AS IS&quot; and &quot;AS
            AVAILABLE&quot; and with all faults and defects without warranty of
            any kind. To the maximum extent permitted under applicable law, the
            Company, on its own behalf and on behalf of its Affiliates and its
            and their respective licensors and service providers, expressly
            disclaims all warranties, whether express, implied, statutory or
            otherwise, with respect to the Service, including all implied
            warranties of merchantability, fitness for a particular purpose,
            title and non-infringement, and warranties that may arise out of
            course of dealing, course of performance, usage or trade practice.
            Without limitation to the foregoing, the Company provides no
            warranty or undertaking, and makes no representation of any kind
            that the Service will meet Your requirements, achieve any intended
            results, be compatible or work with any other software,
            applications, systems or services, operate without interruption,
            meet any performance or reliability standards or be error free or
            that any errors or defects can or will be corrected.
          </p>
          <p className="font conditions-space color-white conditions-text">
            Without limiting the foregoing, neither the Company nor any of the
            company&#39;s provider makes any representation or warranty of any
            kind, express or implied: (i) as to the operation or availability of
            the Service, or the information, content, and materials or products
            included thereon; (ii) that the Service will be uninterrupted or
            error-free; (iii) as to the accuracy, reliability, or currency of
            any information or content provided through the Service; or (iv)
            that the Service, its servers, the content, or e-mails sent from or
            on behalf of the Company are free of viruses, scripts, trojan
            horses, worms, malware, timebombs or other harmful components.
          </p>
          <p className="font conditions-space color-white conditions-text">
            Some jurisdictions do not allow the exclusion of certain types of
            warranties or limitations on applicable statutory rights of a
            consumer, so some or all of the above exclusions and limitations may
            not apply to You. But in such a case the exclusions and limitations
            set forth in this section shall be applied to the greatest extent
            enforceable under applicable law.
          </p>
          <h2 className="font conditions-space color-red conditions-text-title">
            Governing Law
          </h2>
          <p className="font conditions-space color-white conditions-text">
            The laws of the Country, excluding its conflicts of law rules, shall
            govern this Terms and Your use of the Service. Your use of the
            Application may also be subject to other local, state, national, or
            international laws.
          </p>
          <h2 className="font conditions-space color-red conditions-text-title">
            Disputes Resolution
          </h2>
          <p className="font conditions-space color-white conditions-text">
            If You have any concern or dispute about the Service, You agree to
            first try to resolve the dispute informally by contacting the
            Company.
          </p>
          <h2 className="font conditions-space color-red conditions-text-title">
            For European Union (EU) Users
          </h2>
          <p className="font conditions-space color-white conditions-text">
            If You are a European Union consumer, you will benefit from any
            mandatory provisions of the law of the country in which you are
            resident in.
          </p>
          <h2 className="font conditions-space color-red conditions-text-title">
            United States Legal Compliance
          </h2>
          <p className="font conditions-space color-white conditions-text">
            You represent and warrant that (i) You are not located in a country
            that is subject to the United States government embargo, or that has
            been designated by the United States government as a &quot;terrorist
            supporting&quot; country, and (ii) You are not listed on any United
            States government list of prohibited or restricted parties.
          </p>
          <h2 className="font conditions-space color-red conditions-text-title">
            Severability and Waiver
          </h2>
          <h2 className="font conditions-space color-white conditions-text-sub-title">
            Severability
          </h2>
          <p className="font conditions-space color-white conditions-text">
            If any provision of these Terms is held to be unenforceable or
            invalid, such provision will be changed and interpreted to
            accomplish the objectives of such provision to the greatest extent
            possible under applicable law and the remaining provisions will
            continue in full force and effect.
          </p>
          <h2 className="font conditions-space color-white conditions-text-sub-title">
            Waiver
          </h2>
          <p className="font conditions-space color-white conditions-text">
            Except as provided herein, the failure to exercise a right or to
            require performance of an obligation under these Terms shall not
            effect a party&#39;s ability to exercise such right or require such
            performance at any time thereafter nor shall the waiver of a breach
            constitute a waiver of any subsequent breach.
          </p>
          <h2 className="font conditions-space color-red conditions-text-title">
            Translation Interpretation
          </h2>
          <p className="font conditions-space color-white conditions-text">
            These Terms and Conditions may have been translated if We have made
            them available to You on our Service. You agree that the original
            English text shall prevail in the case of a dispute.
          </p>
          <h2 className="font conditions-space color-red conditions-text-title">
            Changes to These Terms and Conditions
          </h2>
          <p className="font conditions-space color-white conditions-text">
            We reserve the right, at Our sole discretion, to modify or replace
            these Terms at any time. If a revision is material We will make
            reasonable efforts to provide at least 30 days&#39; notice prior to
            any new terms taking effect. What constitutes a material change will
            be determined at Our sole discretion.
          </p>
          <p className="font conditions-space color-white conditions-text">
            By continuing to access or use Our Service after those revisions
            become effective, You agree to be bound by the revised terms. If You
            do not agree to the new terms, in whole or in part, please stop
            using the website and the Service.
          </p>
          <h2 className="font conditions-space color-red conditions-text-title">
            Contact Us
          </h2>
          <p className="font conditions-space color-white conditions-text">
            By continuing to access or use Our Service after those revisions
            become effective, You agree to be bound by the revised terms. If You
            do not agree to the new terms, in whole or in part, please stop
            using the website and the Service.
          </p>
          <p className="font conditions-space color-white conditions-text">
            If you have any questions about these Terms and Conditions, You can
            contact us:
          </p>
          <p className="font conditions-space color-white conditions-text">
            By email:{' '}
            <a href="mailto: info@billetero.xyz">info@billetero.xyz</a>
          </p>
        </div>
        <div className="center privacy-back-btn">
          <Button
            class="sup-btn color-white bg-color-transparent"
            name="back"
            label="Go back"
            isSelected={true}
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  )
}
