// Style
import './support.style.scss'

// Type
import { SummaryDataType } from './Support.type'

// React Library
import React from 'react'

// Data
import { ButtonData, ButtonDataType } from './ButtonData'
import { SummaryData } from './SummaryData'
import { SummaryDataTitle } from './SummaryDataTitle'

// Component
import { Summary, SummaryTitle, ButtonIcon, Button } from '../../components'

// Translation
import { useTranslation } from 'react-i18next'

export const Support = () => {
  const { t } = useTranslation()
  const summaryData: { [key: string]: SummaryDataType[] } = SummaryData()
  const [button_Data, setButton_Data] = React.useState<ButtonDataType>()
  const [key, setKey] = React.useState<string>('General')
  const options = ['General', 'Product', 'Security']

  const Button_Data = ButtonData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => setButton_Data(Button_Data), [])

  const handleClick = (e: React.SyntheticEvent) => {
    const { name } = e.currentTarget as HTMLButtonElement

    if (key === name) return
    if (!options.includes(name)) return

    button_Data?.forEach((elem: ButtonDataType[0]) => {
      if (name === elem.name) elem.isSelected = true
      else elem.isSelected = false
    })

    setKey(name)
    handleCloseDetails()
  }

  const handleCloseDetails = () => {
    // Fetch all the details element.
    const details = document.querySelectorAll('details')

    // Close all opened details.
    details.forEach((detail) => {
      if (detail.open) detail.removeAttribute('open')
    })
  }

  const handleContactUs = (e: React.SyntheticEvent) => {
    e.preventDefault()
    window.location.href = 'mailto:info@billetero.xyz'
  }

  return (
    <div className="container">
      <h1 className="font color-white sup-title">FAQs</h1>
      <div className="flex">
        <div className="sup-btn-container">
          {button_Data?.map((elem: ButtonDataType[0], i: number) => {
            return (
              <div key={i}>
                <Button
                  name={elem.name}
                  label={elem.label}
                  class={elem.class}
                  isSelected={elem.isSelected}
                  onClick={handleClick}
                />
              </div>
            )
          })}
        </div>
        <div className="sup-container">
          {summaryData[key].map((data: SummaryDataType, i: number) => {
            return (
              <div key={i}>
                <Summary
                  summaryTitle={data.summaryTitle}
                  detailTitle={data.detailTitle ?? ''}
                  text={data.text ?? ''}
                  bottomText={data.bottomText ?? ''}
                  listText={data.listText ?? []}
                />
              </div>
            )
          })}
        </div>
        <div className="sup-container-summary-title">
          {SummaryDataTitle().map((data: SummaryDataType, i: number) => {
            return (
              <div key={i}>
                <SummaryTitle
                  title={data.title ?? ''}
                  summaryTitle={data.summaryTitle}
                  detailTitle={data.detailTitle ?? ''}
                  text={data.text ?? ''}
                  bottomText={data.bottomText ?? ''}
                  listText={data.listText ?? []}
                />
              </div>
            )
          })}
        </div>
      </div>
      <div className="flex sup-container-text-button">
        <div className="sup-contact-container">
          <h1 className="font color-white sup-contact-text">
            {t('FOR_ANY_QUESTION_PLEASE_CONTACT_US')}
          </h1>
        </div>
        <div className="sup-btn-contact-us">
          <ButtonIcon
            onClick={handleContactUs}
            label={'Contact us'}
            btColor={'bg-color-torch-red'}
            btColorText={'color-white'}
          />
        </div>
      </div>
    </div>
  )
}
