export const en = {
  translation: {
    BILLETERO_IS_YOUR_NEW: 'Billetero is your new',
    SIMPLE_SAFE_AND_SECURE: 'simple, secure Web3 wallet',
    CRYPTO_WALLET: 'for digital assets.',
    SWAP_STORE: 'Store and Swap your',
    SEND_AND_CONNECT: 'tokens. Send',
    YOUR_FRIEND: 'cryptocurrencies to your friends.',
    YOUR_SAFE: 'Connect to apps, games',
    AND_SAFE: 'and marketplaces online.',
    PURCHASES_ONLINE: '',
    WHY_CHOOSE_BILLETERO: 'Why choose Billetero?',
    WHY_CHOOSE_BILLETERO_SUB_TITLE:
      'Whether your a first-time purchaser or long-time adopter of crypto, Billetero makes everything easy.',
    EASY_TO_START: 'Easy to start',
    CARD1_TEXT:
      'No convoluted sign-ups anymore - with just two clicks you’ve got your new wallet ready to use.',
    DATA_PRIVACY: 'Data privacy',
    CARD2_TEXT:
      'We’ll never require your contact details such as phone or email. We’ll never store or share your transaction data. It’s all saved on the blockchains.',
    SMART_SECURITY: 'Smart security',
    CARD3_TEXT:
      'Only you have access to your wallet, not us. Secure your wallet with your personal pin code, FaceID and your pass phrase',
    COMPETITIVE_FEES: 'Competitive fees',
    CARD4_TEXT:
      'We only add a small fee for swapping cryptocurrencies. You can send tokens to your friends for free! All transactions are transparent at checkout.',
    ABOUT_BILLETERO: 'About Billetero',
    ABOUT_1PARA_TEXT:
      'We’re an international team based in the US and Europe. We love creating nice consumer products and believe in simplicity, ease-of-use, beauty and functionality. That’s why we have created Billetero, a new decentralized digital wallet for cryptocurrencies. You can use our wallet to buy crypto tokens, send them to family, friends and others or swap between different currencies.',
    ABOUT_2PARA_TEXT:
      'Our Vision is to accelerate the mainstream adoption of cryptocurrencies, NFTs and blockchain technology, with the mission to empower every person to be able to purchase and share cryptocurrencies and store digital assets in a safe and secure decentralised wallet.',
    ABOUT_3PARA_TEXT:
      'Check out Billetero on Android and iOS, invite your friends and start exploring the world of cryptocurrencies. If you’d like to get in touch you can drop us a line at ',
    FOR_ANY_QUESTION_PLEASE_CONTACT_US: 'For any question please contact us',
    WHAT_IS_CRYPTOCURRENCY: 'What is cryptocurrency?',
    GENERAL_TEXT1:
      'Cryptocurrency is decentralized digital money that is used online. Cryptocurrency adheres to a decentralized form of governance and control as opposed to a central banking authority. Cryptocurrency operates through distributed ledger technology, more commonly known as blockchain.',
    WHAT_IS_A_CRYPTO_BILLETERO_WALLET: 'What is a crypto Billetero wallet?',
    GENERAL_TEXT2:
      'A crypto wallet (cryptocurrency wallet) is software that enables users to buy, sell, store and use cryptocurrency. A crypto wallet allows users to validate an account balance to provide visibility into how much cryptocurrency the user owns. A crypto wallet enables users to both send and receive cryptocurrency transactions.',
    WHAT_IS_A_DECENTRALIZED_WALLET: 'What is a decentralized wallet?',
    GENERAL_TEXT3:
      'A decentralized wallet means that the holder of the keys will be accorded access to the crypto wallet. It allows the holder to use the keys to send or receive cryptocurrency anywhere on the planet. Some people regard a decentralized wallet to be anonymous, but it is not.',
    WHAT_IS_BLOCKCHAIN_TECHNOLOGY: 'What is blockchain technology?',
    GENERAL_TEXT4:
      'Blockchain is essentially a digital ledger of transactions that is duplicated and distributed across the entire network of computer systems on the blockchain.',
    WHAT_IS_A_SMART_CONTRACT: 'What is a smart contract?',
    GENERAL_TEXT5:
      'A smart contract is a self-executing contract with the terms of the agreement between buyer and seller being directly written into lines of code. The code and the agreements are recorded on the blockchain.',
    WHAT_IS_ETHEREUM: 'What is ethereum?',
    GENERAL_TEXT6:
      'Ethereum is a decentralized blockchain platform that establishes a peer-to-peer network that securely executes and verifies application code, called smart contracts. Smart contracts allow participants to transact with each other without a trusted central authority.',
    WHAT_IS_BILLETERO: 'What is Billetero?',
    PRODUCT_TEXT1:
      'Billetero is a decentralized crypto wallet for mobile, available for iOS and Android.',
    HOW_DO_I_USE_BILLETERO: 'How do I use Billetero?',
    PRODUCT_TEXT2:
      'Once you open the app, you can either connect your existing crypto wallet or create a brand new one. You can then buy cryptocurrencies. You can also transfer coins you hold from another wallet to your Billetero wallet. Once you hold cryptocurrencies you can swap them for others, send them to other wallets such as family & friends or apps and services that support crypto as a transaction method.',
    WHAT_PRODUCT_ARE_FEATURED_ON_BILLETERO_APP:
      'What products are featured on Billetero’s app?',
    PRODUCT_TEXT3:
      'You can buy, send and swap cryptocurrencies. You can also connect to other wallets you own and transfer funds from that wallet to your Billetero.',
    HOW_DO_I_CONNECT_TO_AN_EXISTING_WALLET:
      'How do I connect to an existing wallet?',
    PRODUCT_TEXT4:
      'From the opening screen when first using Billetero: Add existing wallet -> Connect a wallet. From your profile screen once you have already created a wallet: Home screen - profile icon  - connect a wallet. There are three ways to connect an existing wallet',
    PRODUCT_TEXT4_1: 'scan the QR code of your external wallet Alternatively',
    PRODUCT_TEXT4_2:
      'if you know the external wallet’s address and have it ready, copy and paste it into the text bar on the Connect a wallet screen.',
    PRODUCT_TEXT4_3:
      'If you have external wallet’s address stored as a file on your phone, you can just upload it on the Connect a wallet screen.',
    WHAT_CRYPTOCURRENCIES_ARE_AVAILABLE_ON_BILLETERO:
      'What cryptocurrencies are available on Billetero?',
    PRODUCT_TEXT5:
      'For now, you can buy and use all cryptocurrency tokens which are part of the Ethereum blockchain. We will keep adding more constantly.',
    IS_BILLETERO_FREE: 'Is Billetero free?',
    PRODUCT_TEXT6:
      'Billetero is free to use. Inside the app you have the option to purchase cryptocurrencies via credit card, Google Pay or Apple Pay.',
    WHAT_ARE_BILLETERO_TRANSACTION_FEES:
      'What are Billetero’s transaction fees?',
    PRODUCT_TEXT7:
      'Billetero charges 0,08% per swapping cryptocurrencies, i.e. 0,08% of the amount which is being swapped to.',
    HOW_MANY_BLOCKCHAINS_CRYPTOCURRENCIES_DOES_BILLETERO_SUPPORT:
      'How many blockchains / cryptocurrencies does Billetero support?',
    PRODUCT_TEXT8:
      'BIlletero currently supports the Ethereum blockchain. We will add more chains very soon.',
    HOW_SECURE_IS_BILLETERO: 'How secure is Billetero?',
    SECURITY_TEXT1: 'There are two main ways to secure your Billetero wallet.',
    SECURITY_TEXT1_1:
      'Your passphrase which is being randomly generated when you create a new wallet is your private key to your wallet. Only you know and own it. Save it and don’t lose it.',
    SECURITY_TEXT1_2: 'Protect your wallet via a passcode and/or using FaceID.',
    SECURITY_TEXT1_BOTTOM_TEXT:
      'Furthermore, Billetero has been built using web3 platforms that add additional layers of security to the mobile application, such as a client and master key as well as class-level permissions, pointer permissions, authentication permissions and more. Feel free to reach out on info@billtero.xyz to find out more about security measures.',
    DOES_BILLETERO_PROTECT_MY_PRIVACY: 'Does Billetero protect my privacy?',
    SECURITY_TEXT2_1:
      'Personal data - as opposed to centralized apps and websites, Billetero is a decentralized platform. That means we will never require your personal contact details such as email or phone number. You keep your privacy. Unless you share your email to receive product and marketing updates voluntarily, we will never send you any SPAM.',
    SECURITY_TEXT2_2:
      'Transactions - Billetero does not store any transaction data like other centralized fintech or banking sites. They are all on the blockchain.',
    SECURITY_TEXT2_3:
      'Accesses - You are the only person who knows your passphrase. We have no access to it, you do. That’s why it’s essential to write it down and save it in a file so you don’t lose it. If you should lose it we can’t help you get it back, as we don’t know it. The same applies to the passcode you create to protect your wallet.',
    GENERAL: 'General',
    PRODUCT: 'Product',
    SECURITY_PRIVACY: 'Security & privacy',
    GET_STARTER_DOWNLOAD_THE_APP: 'Get started and download Billetero',
    DOWNLOAD: 'download',
    CONTACT_US: 'Contact us',
    LINKEDIN: 'Linkedin',
    TWITTER: 'Twitter',
    TELEGRAM: 'Telegram',
    PRIVACY_POLICY: 'Privacy policy',
    TERMS_AND_CONDITION: 'Terms and conditions',
    COOKIES_POLICY: 'Cookies policy',
    GET_IN_TOUCH: 'Get in touch',
    EMAIL: 'Email',
    FEATURES: 'features',
    ABOUT: 'about',
    CONTACT: 'contact',
  },
}
