// React Library
import React from 'react'

// Style
import './Summary.style.scss'

// Type
import { summaryType } from './type'

export const Summary = (props: summaryType) => {
  React.useEffect(() => {
    // Fetch all the details element.
    const details = document.querySelectorAll('details')

    // Add the onclick listeners.
    details.forEach((targetDetail) => {
      targetDetail.addEventListener('click', () => {
        // Close all the details that are not targetDetail.
        details.forEach((detail) => {
          if (detail !== targetDetail) {
            detail.removeAttribute('open')
          }
        })
      })
    })
  })

  return (
    <>
      <details className="summary-detail">
        <summary className="font color-red summary-title">
          <b className="summary-b">{props.summaryTitle}</b>
        </summary>
        <h1 className="font color-white summary-detail-title">
          {props.detailTitle}
        </h1>
        <div>
          <p className="font color-white summary-detail-text">{props.text}</p>
          {props.listText?.length ? (
            <ol className="font color-white summary-list">
              {props.listText.map((elem, i) => {
                return <li key={i}>{elem}</li>
              })}
            </ol>
          ) : null}
          <p className="font color-white summary-detail-text">
            {props.bottomText}
          </p>
        </div>
      </details>
    </>
  )
}
