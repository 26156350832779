// Home Page Style
import './HomePage.scss'

// React library
import React from 'react'

// SVG
import arrowDown from '../../assets/svg/receive-color-white.svg'
import appleIcon from '../../assets/svg/appstore.svg'
import playIcon from '../../assets/svg/play.svg'

import phone from '../../assets/svg/Group 10 Copy 2.svg'
import coins from '../../assets/svg/tutorial-1-2.svg'

// Components
import { ButtonIcon } from '../../components'

// Translation
import { useTranslation } from 'react-i18next'

export const HomePage = () => {
  const { t } = useTranslation()
  const clickHandler = (link: string) => {
    window.open(link)
  }

  return (
    <div className="container flex">
      <div className="home-container">
        <div className="home-text-container">
          <h1 className="nav-violet-class-controller font color-violet home-text">
            {t('BILLETERO_IS_YOUR_NEW')}
          </h1>
          <h1 className="font color-white home-text">
            {t('SIMPLE_SAFE_AND_SECURE')}
          </h1>
          <h1 className="font color-violet home-text">{t('CRYPTO_WALLET')}</h1>
        </div>
        <div className="flex space-margin-top">
          <div className="space-button">
            <ButtonIcon
              icon={appleIcon}
              btColorText={'color-white'}
              btColor={'bg-color-violet'}
              label="download"
              onClick={() =>
                clickHandler('https://billetero.sng.link/D67p7/7ipm/hwjv')
              }
            />
          </div>
          <div>
            <ButtonIcon
              icon={playIcon}
              btColorText={'color-white'}
              btColor={'bg-color-violet'}
              label="download"
              onClick={() =>
                clickHandler('https://billetero.sng.link/D67p7/saok/er8y')
              }
            />
          </div>
        </div>
      </div>
      <div className="arrow-down-container">
        <img className="arrow-down" src={arrowDown} alt="Arrow down" />
      </div>
      <div className="phone-card-style">
        <img className="home-page-coins-phone" src={coins} alt="PhoneCard" />
        <img className="home-page-img-phone" src={phone} alt="PhoneCard" />
      </div>
    </div>
  )
}
