// MainPage
import { MainPage, PrivacyPolicy, Conditions, Logo } from './Pages'

// React Router
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import React from 'react'

export const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/logo" element={<Logo />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<Conditions />} />
        <Route path="*" element={<MainPage />} />
      </Routes>
    </Router>
  )
}
