// Style
import './Input.style.scss'

// React library
import React from 'react'

// Type
import { inputType } from './type'

export const Input = (props: inputType) => {
  return (
    <div className="get-starter-input-container">
      {props.icon ? (
        <button
          className={`${props.iconClass} get-starter-icon-input`}
          onClick={props.onClickIcon}
          type={props.iconType}
        >
          <img src={props.icon} alt="Input Icon" />
        </button>
      ) : null}
      <input
        className="font bg-color-violet get-starter-input"
        name={props.name}
        value={props.value}
        type={props.inputType}
        placeholder={props.placeholder}
        required={props.isRequired}
        onChange={props.onChange}
        id={props.id}
      />
    </div>
  )
}
