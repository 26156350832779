// Button Icon Style
import './ButtonIcon.style.scss'

// React library
import React from 'react'

// Type
import { ButtonIconType } from './type'

export const ButtonIcon = (props: ButtonIconType) => {
  return (
    <>
      <div className="bt-container">
        <button
          onClick={props.onClick}
          className={`${props.btColorText} ${props.btColor} ${
            props.icon ? 'button-icon-style' : 'button-style'
          } flex center font button-style`}
        >
          {props.label}{' '}
          {props.icon ? (
            <img className="btn-icon" src={props.icon} alt="Button Icon" />
          ) : null}
        </button>
      </div>
    </>
  )
}
