// Style
import './FeaturePage.style.scss'

// SVG
import sendCard from '../../assets/img/send@2x.png'
import receiveCard from '../../assets/img/receive@2x.png'
import swapCard from '../../assets/img/swap@2x.png'
import PhoneCard from '../../assets/svg/Group 10 Copy 4.svg'

// React Library
import React from 'react'

// Animation controller
import { AnimationController } from '../../controller'

// Translation
import { useTranslation } from 'react-i18next'

export const FeaturePage = () => {
  const { t } = useTranslation()
  const [setClassValue] = AnimationController()

  React.useEffect(() => setClassValue('.feature-img'), [setClassValue])

  return (
    <div className="container flex no-wrap">
      <div className="feature-page-container">
        <h1 className="font color-white feature-page-text">
          {t('SWAP_STORE')}
        </h1>
        <h1 className="font color-white feature-page-text">
          {t('SEND_AND_CONNECT')}
        </h1>
        <h1 className="font color-white feature-page-text space-margin-bottom">
          {t('YOUR_FRIEND')}
        </h1>
      </div>
      <div className="feature-phone-card-style">
        <div className="img-container-feature-page">
          <img
            className="feature-img img-feature-page hidden animation-right-to-left"
            src={sendCard}
            alt="Send card icon"
          />
          <img
            className="feature-img img-space-feature-page hidden animate pop"
            src={receiveCard}
            alt="Receive card icon"
          />
          <img
            className="feature-img img-feature-page hidden animation-left-to-right"
            src={swapCard}
            alt="Swap card icon"
          />
        </div>
        <img className="feature-phone-img" src={PhoneCard} alt="PhoneCard" />
      </div>
    </div>
  )
}
